import { SectionRowViewModel } from './SectionRowViewModel';
import { ProductType } from '../../../../../../../../../models/enum/dto/product-type.enum';
import { VariantTypeUtils } from '../../../../../../../../../utils/variant-type-utils';
import { SectionColumnConfigDataValue } from '../../../../../../../../../models/menu/section/section-column-config';

export class SectionRowViewModelPlantlifeNonSmokable extends SectionRowViewModel {

  public getSize(): string {
    const variant = this.rowVariants?.firstOrNull();
    if (VariantTypeUtils.isPreRollType(variant?.variantType)) {
      const quantity = variant?.packagedQuantity;
      const size = variant?.getFormattedUnitSize();
      return `${quantity}x${size}`;
    } else if (VariantTypeUtils.isCapsuleType(variant?.variantType)) {
      const n = variant?.packagedQuantity ?? 0;
      return n > 0 ? `${n} cap${n > 1 ? 's' : ''}` : '';
    } else if (variant?.productType === ProductType.Edible
      && !VariantTypeUtils.isReadyToDrinkBeverageType(variant?.variantType)) {
      return `${variant?.packagedQuantity} pc`;
    } else if (variant?.packagedQuantity > 1) {
      return `${variant?.packagedQuantity} pc`;
    } else {
      return super.getSize();
    }
  }

  getReadableStrainType(
    strainTypeMode: SectionColumnConfigDataValue
  ): string {
    // Hardcoded logic - Plantlife NS should always return symbol
    return super.getReadableStrainType(SectionColumnConfigDataValue.StrainTypeSymbol);
  }

  public getThcAndCbd(): string {
    const unitOfMeasureString = this.rowVariants?.firstOrNull()?.getCannabinoidUnitOfMeasureString(this.menu);
    const removeTrailingZeros = (s: string | null) => s?.replace(/\.0+$/, '');
    // THC
    const thcVal = this.displayCannabinoidInRanges()
      ? this.getRowRangeCannabinoid('THC', SectionColumnConfigDataValue.NumericValue, 1)
      : this.getCannabinoidAsString('THC', '', SectionColumnConfigDataValue.NumericValue, 1);
    let thc: string | null = removeTrailingZeros(thcVal);
    if (thc.includes('<1')) thc = thc.replace('<1', '');
    thc = thc.replace(/\s/g, '');
    // CBD
    const cbdVal = this.displayCannabinoidInRanges()
      ? this.getRowRangeCannabinoid('CBD', SectionColumnConfigDataValue.NumericValue, 1)
      : this.getCannabinoidAsString('CBD', '', SectionColumnConfigDataValue.NumericValue, 1);
    let cbd: string | null = removeTrailingZeros(cbdVal);
    if (cbd.includes('<1')) cbd = cbd.replace('<1', '');
    cbd = cbd.replace(/\s/g, '');
    if (!!thc && !!cbd) {
      // If cannabinoids are in ranges, display them on separate lines, else separate with bar
      return `${thc}${this.displayCannabinoidInRanges() ? '|\n' : '|'}${cbd}${unitOfMeasureString}`;
    } else if (!!thc) {
      return `${thc}${unitOfMeasureString}`;
    } else if (!!cbd) {
      return `${cbd}${unitOfMeasureString}`;
    } else {
      return '-';
    }
  }

}
