import { FONT, FontStyleSheet } from '../font-style-sheets';
import { PrintCardMenu } from './print-card-menu';

export abstract class FireAndFlowerOldStylePrintCardMenu extends PrintCardMenu {

  getFontStyleSheets(): FontStyleSheet[] {
    return [FONT.arialBold];
  }

}
