import { PrintCardMenu } from './print-card-menu';
import { AssetLibraryType } from '../../enum/dto/asset-library-type.enum';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class TrueNorthPortraitPrintCardMenu extends PrintCardMenu {

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.gilroyExtraBold,
      FONT.gilroySemiBold,
      FONT.montserratExtraBold,
      FONT.montserratMedium,
      FONT.montserratExtraBoldItalic,
      FONT.montserratBold,
      FONT.montserratSemiBold
    ];
  }

  shouldFetchVariantAssets(): boolean {
    return true;
  }

  variantAssetTypeSortOrder(): string[] {
    return [
      AssetLibraryType.PrimaryBrand,
      AssetLibraryType.AlternateBrand,
      AssetLibraryType.Brand,
    ];
  }

  shouldStandardizeDominantStrainType(): boolean {
    return false;
  }

}
