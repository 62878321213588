import { PrintMenu } from '../print-menu';
import { Type } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { ColorUtils } from '../../../utils/color-utils';
import { StrainType } from '../../enum/dto/strain-classification.enum';
import type { SectionWithProducts } from '../section/section-with-products';
import { ProductType } from '../../enum/dto/product-type.enum';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';
import { PriceUtils } from '../../../utils/price-utils';
import { Fraction } from '../../enum/shared/fraction.enum';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class BudSupplyMenu extends PrintMenu {

  public static readonly supportedDecimalsThatCanBeFractions: Fraction[] = [
    Fraction.Quarter,
    Fraction.Half,
    Fraction.ThreeQuarters,
  ];

  getPageTopMargin(): string {
    return '0.5in';
  }

  getPageBottomMargin(): string {
    return '0.5in';
  }

  getPageLeftMargin(): string {
    return '0.5in';
  }

  getPageRightMargin(): string {
    return '0.5in';
  }

  getThemeClass(): string {
    return 'bud-supply-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.FirstPage;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  brandInlineWithProductName(): boolean {
    return true;
  }

  getProductWrapperClass(odd: boolean) {
    return odd ? 'product-row-overlay' : '';
  }

  getRowColorFromStrainType(odd: boolean, rowViewModel: SectionRowViewModel): string {
    switch (rowViewModel?.rowStrainType()) {
      case StrainType.Indica: return '#F4E0D8';
      case StrainType.Sativa: return '#C7D6BC';
      case StrainType.Hybrid: return '#F9E5C7';
      case StrainType.Blend:  return '#BDD6EE';
      case StrainType.CBD:    return '#E4DEF1';
    }
    return null;
  }

  interceptLineItemRowBackgroundColor(color: string, odd: boolean, rowViewModel: SectionRowViewModel): string {
    return (!!color && odd) ? ColorUtils.replaceAlphaInRGBA(color, 0.5) : color;
  }

  getShowProductSubtitle(): boolean {
    return false;
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.bebasNeueProBold,
      FONT.bebasNeueProRegular,
      FONT.myriadProRegular,
      FONT.myriadProBold,
      FONT.myriadProSemiBold
    ];
  }

  getPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel,
  ): string {
    const decimal = super.getPriceDecimal(priceStream, locationId, s, rowVM, colVm);
    return PriceUtils.getCertainDecimalsAsAsciiFractions(decimal, BudSupplyMenu.supportedDecimalsThatCanBeFractions);
  }

  getSecondaryPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel
  ): string {
    const decimal = super.getSecondaryPriceDecimal(priceStream, locationId, s, rowVM, colVm);
    return PriceUtils.getCertainDecimalsAsAsciiFractions(decimal, BudSupplyMenu.supportedDecimalsThatCanBeFractions);
  }

  getMakeHeaderSectionImageFillEntireSection(): boolean {
    return true;
  }

  getTitleSectionBottomMargin(): string {
    return '0';
  }

  getSizeColumnProductTypes(): ProductType[] {
    return [
      ProductType.Flower,
      ProductType.Concentrate,
      ProductType.Vape,
      ProductType.Wellness,
      ProductType.Oil,
      ProductType.Beverage
    ];
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.ProductTitle, 0],
      [SectionColumnProductInfoType.StrainType, 1],
      [SectionColumnProductInfoType.Brand, 2],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(3),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(4),
      [SectionColumnProductInfoType.QuantityAndSize, 5],
      [SectionColumnProductInfoType.Size, 6],
      [SectionColumnProductInfoType.Quantity, 7],
      [SectionColumnProductInfoType.VariantPrice, 8],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 9],
      [SectionColumnProductInfoType.Badge, 10],
    ]);
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

}
