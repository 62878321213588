import { FONT, FontStyleSheet } from '../font-style-sheets';
import { PrintLabelMenu } from './print-label-menu';

export class BaseInventoryPrintLabelMenu extends PrintLabelMenu {

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.poppinsBold,
      FONT.poppinsMedium,
      FONT.poppinsBoldItalic,
      FONT.poppinsItalic
    ];
  }

  shouldStandardizeDominantStrainType(): boolean {
    return false;
  }

}
