import { Deserializable } from '../../protocols/deserializable';
import { MenuStyleObject } from '../../enum/dto/menu-style-object.enum';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { VariantBadge } from '../../product/dto/variant-badge';
import { SortUtils } from '../../../utils/sort-utils';
import { Terpene } from '../../enum/shared/terpene';
import { StringUtils } from '../../../utils/string-utils';
import { Cannabinoid } from '../../enum/shared/cannabinoid';
import { exists } from '../../../functions/exists';

export class DisplayAttribute implements Deserializable, UniquelyIdentifiable {

  constructor(companyId?: number, id?: string, objectType?: MenuStyleObject) {
    this.companyId = companyId;
    this.id = id;
    this.objectType = objectType;
    this.displayName = '';
  }

  companyId: number;
  id: string;
  locationId: number;
  objectId: string;
  objectType: MenuStyleObject;
  displayName: string;
  minTHC: string;
  maxTHC: string;
  THC: string;
  CBD: string;
  minCBD: string;
  maxCBD: string;
  TAC: string;
  minTAC: string;
  maxTAC: string;
  badgeIds: string[];
  badges: VariantBadge[];
  defaultLabel: string;
  presentCannabinoids: Cannabinoid[];
  totalTerpene: string;
  minTotalTerpene: string;
  maxTotalTerpene: string;
  topTerpene: string;
  presentTerpenes: Terpene[];

  // Inherited
  inheritedDisplayAttribute: DisplayAttribute;

  static cannabinoidProperties(): string[] {
    const properties: string[] = [];
    Object.values(Cannabinoid)?.forEach(cannabinoid => {
      properties.push(cannabinoid);
      properties.push(`min${cannabinoid}`);
      properties.push(`max${cannabinoid}`);
    });
    return properties;
  }

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    const inheritedDA = this.inheritedDisplayAttribute;
    this.inheritedDisplayAttribute = Deserialize?.instanceOf(DisplayAttribute, inheritedDA);
    this.badgeIds = Array.from(this.badgeIds || []);
    this.badges = Deserialize?.arrayOf(VariantBadge, this.badges);
  }

  public getDisplayName(): string {
    return this.displayName || this.inheritedDisplayAttribute?.displayName || null;
  }

  public getTopTerpene(useTerpeneRange: boolean, enabledTerpenesPascalCased: string[]): string {
    return this.getExplicitlySetTopTerpene()
        || this.getInferredTopTerpene(useTerpeneRange, enabledTerpenesPascalCased);
  }

  protected getExplicitlySetTopTerpene(): string {
    return this.topTerpene || this.inheritedDisplayAttribute?.topTerpene || null;
  }

  /**
   * Uses max value if useTerpeneRange is true. I don't know if this is right.
   */
  protected getInferredTopTerpene(useTerpeneRange: boolean, enabledTerpenesPascalCased: string[]): string {
    const top: { terpene: string, value: number } = { terpene: undefined, value: undefined };
    enabledTerpenesPascalCased?.forEach(terpenePascalCased => {
      const accessor = useTerpeneRange ? `max${terpenePascalCased}` : StringUtils.camelize(terpenePascalCased);
      const value = this[accessor] || this.inheritedDisplayAttribute?.[accessor];
      if (exists(value)) {
        const valueAsNumber = parseFloat(value);
        if (Number.isFinite(valueAsNumber) && (valueAsNumber > top.value || top.value === undefined)) {
          top.terpene = StringUtils.splitIntoSentenceByCapitalLetters(terpenePascalCased);
          top.value = valueAsNumber;
        }
      }
    });
    return top?.terpene;
  }

  public getBadges(): VariantBadge[] {
    const displayAttributeBadges = this.badges ?? [];
    const inheritedDisplayAttributeBadges = this.inheritedDisplayAttribute?.badges ?? [];
    if (displayAttributeBadges.length > 0) {
      return displayAttributeBadges;
    } else if (inheritedDisplayAttributeBadges.length > 0) {
      return inheritedDisplayAttributeBadges;
    } else {
      return [];
    }
  }

  isCompanyDA(): boolean {
    return this.companyId === this.locationId;
  }

  isLocationDA(): boolean {
    return this.companyId !== this.locationId;
  }

  /**
   * Cannabinoids are stored as strings, therefore, we need to parse their numerical values,
   * round them, and then replace the old string number with the new rounded string number.
   */
  roundCannabinoids(shouldRound: boolean): void {
    if (!shouldRound) return;
    const cannabinoidProperties = DisplayAttribute.cannabinoidProperties();
    cannabinoidProperties.forEach(prop => {
      const cannabinoidString = this[prop]?.match(/(\d+\.?\d*)/)?.[0];
      const roundedCannabinoidNumber = Math.round(parseFloat(this[prop]));
      if (Number.isFinite(roundedCannabinoidNumber)) {
        const rounded = roundedCannabinoidNumber?.toString(10);
        this[prop] = (this[prop] as string)?.replace(cannabinoidString, rounded);
      }
    });
    // this will keep chaining upwards for all linked inherited display attributes and stop when chain is broken
    this.inheritedDisplayAttribute?.roundCannabinoids(shouldRound);
  }

  getUniqueIdentifier(): string {
    let uniqueId = `${this.companyId}
        -${this.id}
        -${this.locationId}
        -${this.objectId}
        -${this.objectType}
        -${this.displayName}
        -${this.totalTerpene}
        -${this.minTotalTerpene}
        -${this.maxTotalTerpene}
        -${this.topTerpene}
        -${this.badgeIds?.sort()?.join(',')}
        -${this.badges?.sort(SortUtils.sortBadges)?.map(b => b?.getUniqueIdentifier())?.join(',')}
        -${this.inheritedDisplayAttribute?.getUniqueIdentifier()}`;
      Object.values(Cannabinoid)?.forEach(cannabinoid => {
        uniqueId += `-${this[cannabinoid]}`;
        uniqueId += `-${this[`min${cannabinoid}`]}`;
        uniqueId += `-${this[`max${cannabinoid}`]}`;
      });
      Object.values(Terpene)?.forEach(terpene => {
        const camelCaseTerpene = StringUtils.camelize(terpene);
        const pascalCaseTerpene = StringUtils.toPascalCase(terpene);
        uniqueId += `-${this[camelCaseTerpene]}`;
        uniqueId += `-${this[`min${pascalCaseTerpene}`]}`;
        uniqueId += `-${this[`max${pascalCaseTerpene}`]}`;
      });
    return uniqueId;
  }

}
