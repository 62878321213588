import { FONT, FontStyleSheet } from '../font-style-sheets';
import { PrintCardMenu } from './print-card-menu';

export class FikaAccessoriesPrintCardMenu extends PrintCardMenu {

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.neueHaasGroteskDisplayRegular,
      FONT.neueHaasGroteskDisplayBold,
      FONT.neueHaasGroteskDisplayBoldItalic,
      FONT.neueHaasGroteskDisplayMedium,
      FONT.neueHaasGroteskDisplayMediumItalic,
      FONT.neueHaasGroteskDisplayRegularItalic,
      FONT.neueHaasGroteskDisplaySemiBold,
    ];
  }

  shouldStandardizeDominantStrainType(): boolean {
    return false;
  }

}
