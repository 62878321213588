import { FONT, FontStyleSheet } from '../font-style-sheets';
import { PrintCardMenu } from './print-card-menu';

export class GenericPrintCardMenu extends PrintCardMenu {

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.poppinsMedium,
      FONT.poppinsBold,
      FONT.poppinsRegular,
      FONT.poppinsSemiBold,
      FONT.poppinsExtraBold,
      FONT.poppinsBoldItalic
    ];
  }

  shouldFetchVariantAssets(): boolean {
    return true;
  }

  shouldStandardizeDominantStrainType(): boolean {
    return false;
  }

}
