import { Type } from '@angular/core';
import { PrintMenu } from '../print-menu';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { ColumnGrouping } from '../section/column-grouping';
import { Section } from '../section/section';
import { Product } from '../../product/dto/product';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { BandedRowMode } from '../../enum/shared/banded-row-mode.enum';
import { BandedRowColorContrast } from '../../enum/shared/banded-row-color-contrast.enum';
import { ColWidth } from '../../shared/col-width';
import { exists } from '../../../functions/exists';
import { ColorUtils } from '../../../utils/color-utils';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import { ColumnUtils } from '../../../utils/column-utils';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class FireAndFlowerPrintMenu extends PrintMenu {

  getPageTopMargin(): string {
    return '0.5in';
  }

  getPageBottomMargin(): string {
    return '0.5in';
  }

  getPageLeftMargin(): string {
    return '0.5in';
  }

  getPageRightMargin(): string {
    return '0.5in';
  }

  getThemeClass(): string {
    return 'fire-and-flower-print-theme';
  }

  override getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  override getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.FirstPage;
  }

  override getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.futuraPtDemi,
      FONT.futuraPtMedium,
      FONT.futuraPtBook,
      FONT.futuraPtBold,
      FONT.openSansSemiBold
    ];
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.ProductTitle, 0],
      [SectionColumnProductInfoType.Label, 1],
      [SectionColumnProductInfoType.Badge, 2],
      [SectionColumnProductInfoType.StrainType, 3],
      [SectionColumnProductInfoType.Brand, 4],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(5),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(6),
      [SectionColumnProductInfoType.QuantityAndSize, 7],
      [SectionColumnProductInfoType.Quantity, 8],
      [SectionColumnProductInfoType.Size, 9],
      [SectionColumnProductInfoType.VariantPrice, 10],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 11],
    ]);
  }

  override getProductSubtitle(
    section: Section,
    product: Product,
    rowVm: SectionRowViewModel
  ): string {
    return rowVm?.getBrand();
  }

  override getShouldInflateColumnHeaderForRow(
    s: Section,
    col: SectionColumnViewModel,
    rows: SectionRowViewModel[]
  ): boolean {
    const badgeCol = col?.columnType === SectionColumnProductInfoType.Badge;
    const labelCol = col?.columnType === SectionColumnProductInfoType.Label;
    if (badgeCol || labelCol) {
      return exists(col?.columnTitle);
    }
    return super.getShouldInflateColumnHeaderForRow(s, col, rows);
  }

  override getShouldInflateColumnForRow(
    s: Section,
    col: SectionColumnViewModel,
    row: SectionRowViewModel
  ): boolean {
    const badge = col?.columnType === SectionColumnProductInfoType.Badge;
    return !badge || (badge && exists(row?.hasBadges()));
  }

  override hideProductLabelColumnWhenEmpty(): boolean {
    return true;
  }

  override ignoreLabelColumnWidth(): boolean {
    return true;
  }

  override getThemeSpecifiedColumnViewModels(
    sectionRowViewModels: SectionRowViewModel[],
    sectionRowViewModel: SectionRowViewModel,
    widths: ColWidth[] = this.getColWidths(sectionRowViewModel)
  ): SectionColumnViewModel[] {
    const vms = super.getThemeSpecifiedColumnViewModels(sectionRowViewModels, sectionRowViewModel, widths);
    if (!sectionRowViewModel.hideLabel) {
      const layoutType = sectionRowViewModel?.getLayoutType();
      const labelCol = new SectionColumnViewModel(layoutType, null);
      labelCol.columnType = SectionColumnProductInfoType.Label;
      labelCol.columnTitle = '';
      labelCol.widthPercentage = '15';
      vms.splice(1, 0, labelCol);
    }
    return vms;
  }

  override brandInlineWithProductName(): boolean {
    return false;
  }

  override getShowInlineLabels(): boolean {
    return false;
  }

  override getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return false;
  }

  override bandedRowsDefaultEnabled(): boolean {
    return true;
  }

  override bandedRowMode(): BandedRowMode {
    return BandedRowMode.Odd;
  }

  override bandedRowColorContrast(): BandedRowColorContrast {
    return BandedRowColorContrast.Lighten;
  }

  override getSectionHeaderUnderlined(): boolean {
    return false;
  }

  override getSectionTitleUnderlineColor(section: Section): string {
    const headerTextColor = section?.getSectionHeaderTitleColor();
    return exists(headerTextColor) ? headerTextColor : super.getSectionTitleUnderlineColor(section);
  }

  override getSectionTitleBorderColor(section:Section): string {
    const headerTextColor = section?.getSectionHeaderTitleColor();
    return exists(headerTextColor) ? headerTextColor : super.getSectionTitleBorderColor(section);
}

  override getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  override getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  override getDefaultBandingHexString(): [number, number, number] {
    return ColorUtils.hexToRgb('#d2d2d2');
  }

  override getDefaultBandingOpacity(): number {
    return 1;
  }

  override getTitleSectionTopMargin(): string {
    const topMargin = (16 / 11) * 1.5;
    return `${topMargin}rem`;
  }

}
