import { PrintMenu } from '../print-menu';
import { Type } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import { MenuSectionHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section-header/menu-section-header.component';
import { PrintFooterLayoutType } from '../../enum/shared/print-footer-layout-type.enum';
import { ProductMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/product-menu-footer.component';
import { SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { StrainType } from '../../enum/dto/strain-classification.enum';
import { DoubleDutchFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/double-dutch-footer/double-dutch-footer.component';
import { SectionType } from '../../enum/dto/section-type.enum';
import { DoubleDutchSectionHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section-header/double-dutch-section-header/double-dutch-section-header.component';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { DoubleDutchEmptyFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/double-dutch-footer/double-dutch-empty-footer/double-dutch-empty-footer.component';
import { Section } from '../section/section';
import { EmptySection } from '../section/empty-section';
import { DoubleDutchEmptySection } from '../section/double-dutch-empty-section';
import { SortVariantUtils } from '../../../utils/sort-variant-utils';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class DoubleDutchMenu extends PrintMenu {

  protected override deserializeSections() {
    this.sections?.forEach(section => {
      const isProductSection = section?.sectionType === SectionType.Product;
      if (isProductSection) section.sectionType = SectionType.DoubleDutchProduct;
    });
    this.sections = window?.injector?.Deserialize?.arrayOf(Section, this.sections) ?? [];
    this.fixProductBrands(this.sections);
  }

  protected override deserializeTemplateSections() {
    this.templateSections?.forEach(templateSection => {
      const isProductSection = templateSection?.sectionType === SectionType.Product;
      if (isProductSection) templateSection.sectionType = SectionType.DoubleDutchProduct;
    });
    this.templateSections = window?.injector?.Deserialize?.arrayOf(Section, this.templateSections) ?? [];
    this.fixProductBrands(this.templateSections);
  }

  override getEmptySectionType(): Type<EmptySection> {
    return DoubleDutchEmptySection;
  }

  getPageTopMargin(): string {
    return '0.25in';
  }

  getPageBottomMargin(): string {
    return '0.25in';
  }

  getPageLeftMargin(): string {
    return '0.25in';
  }

  getPageRightMargin(): string {
    return '0.25in';
  }

  getTitleSectionTopMargin(): string {
    return '0';
  }

  getTitleSectionBottomMargin(): string {
    return '0';
  }

  getThemeClass(): string {
    return 'double-dutch-theme';
  }

  getDisplayPadding(): string {
    return '0rem';
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.AllPages;
  }

  getSectionsHeaderType(): Type<MenuSectionHeaderComponent> {
    return DoubleDutchSectionHeaderComponent;
  }

  getPrintFooterLayoutType(): PrintFooterLayoutType {
    return this.menuOptions?.printFooterLayout || PrintFooterLayoutType.AllPagesFixed;
  }

  getFooterType(): Type<ProductMenuFooterComponent> {
    return DoubleDutchFooterComponent;
  }

  getEmptyFooterReplacement(): Type<ProductMenuFooterComponent> {
    return DoubleDutchEmptyFooterComponent;
  }

  getShowStrainTypes(): boolean {
    return false;
  }

  getUniqueClassificationList(): StrainType[] {
    return super.getUniqueClassificationList()?.sort(SortVariantUtils.doubleDutchStrainTypeSort);
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return false;
  }

  getAllowedBadgeCount(): number {
    return 1;
  }

  getShowBadgesUnderSubtitle(): boolean {
    return false;
  }

  getShowBadgesInline(): boolean {
    return true;
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.oswaldMedium,
      FONT.oswaldBold,
      FONT.oswaldLight
    ];
  }

  getColorBackgroundAssetBorderRadius(): string {
    return '0.7275rem';
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.Asset, 0],
      [SectionColumnProductInfoType.StrainType, 1],
      [SectionColumnProductInfoType.ProductTitle, 2],
      [SectionColumnProductInfoType.Brand, 3],
      [SectionColumnProductInfoType.Badge, 4],
      [SectionColumnProductInfoType.QuantityAndSize, 5],
      [SectionColumnProductInfoType.Quantity, 6],
      [SectionColumnProductInfoType.Size, 7],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(8),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(9),
      [SectionColumnProductInfoType.VariantPrice, 10],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 11],
    ]);
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

}
