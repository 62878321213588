import { Type } from '@angular/core';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { SectionColumnCannabinoidType, SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { PrintMenu } from '../print-menu';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import { BandedRowMode } from '../../enum/shared/banded-row-mode.enum';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class TightPrintMenu extends PrintMenu {

  getPageBottomMargin(): string {
    return '0.5in';
  }

  getPageTopMargin(): string {
    return '0.5in';
  }

  getPageLeftMargin(): string {
    return '0.5in';
  }

  getPageRightMargin(): string {
    return '0.5in';
  }

  getThemeClass(): string {
    return 'tight-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.FirstPage;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getProductWrapperClass(odd: boolean) {
    return odd ? 'tight-print' : 'tight-print product-row-overlay';
  }

  bandedRowMode(): BandedRowMode {
    return BandedRowMode.Even;
  }

  bandedRowsDefaultEnabled(): boolean {
    return true;
  }

  getShowProductSubtitle(): boolean {
    return false;
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.rubikBold,
      FONT.rubikMediumBold,
      FONT.latoRegular,
      FONT.latoBold,
      FONT.latoRegular,
      FONT.latoBlack
    ];
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.ProductTitle, 0],
      [SectionColumnProductInfoType.Brand, 1],
      [SectionColumnProductInfoType.StrainType, 2],
      [SectionColumnProductInfoType.Badge, 3],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(5, false),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(6),
      [SectionColumnProductInfoType.QuantityAndSize, 6],
      [SectionColumnProductInfoType.Quantity, 7],
      [SectionColumnProductInfoType.Size, 8],
      [SectionColumnCannabinoidType.THCAndCBD, 9],
      [SectionColumnProductInfoType.VariantPrice, 10],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 11],
    ]);
  }

}
