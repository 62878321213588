import { PrintMenu } from '../print-menu';
import { Type } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import { MenuSectionHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section-header/menu-section-header.component';
import { PrintFooterLayoutType } from '../../enum/shared/print-footer-layout-type.enum';
import { ProductMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/product-menu-footer.component';
import { SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { StrainType } from '../../enum/dto/strain-classification.enum';
import { WrappingSwimLaneOverflow } from '../../enum/shared/swim-lane-overflow.enum';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { SwimlaneClassificationAndBadgesLegendFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/swimlane-classification-legend-footer/swimlane-classification-and-badges-legend-footer.component';
import { ComeToBaskSectionHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section-header/come-to-bask-section-header/come-to-bask-section-header.component';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { SortVariantUtils } from '../../../utils/sort-variant-utils';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class ComeToBaskTrifoldMenu extends PrintMenu {

  protected deserializeSections() {
    super.deserializeSections();
    this.fixProductBrands(this.sections);
  }

  protected deserializeTemplateSections() {
    super.deserializeTemplateSections();
    this.fixProductBrands(this.templateSections);
  }

  getPageTopMargin(): string {
    return '0.25in';
  }

  getPageBottomMargin(): string {
    return '0.25in';
  }

  getPageLeftMargin(): string {
    return '0.25in';
  }

  getPageRightMargin(): string {
    return '0.25in';
  }

  getThemeClass(): string {
    return 'come-to-bask-trifold-theme';
  }

  getDisplayPadding(): string {
    return '0rem';
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.None;
  }

  getSectionsHeaderType(): Type<MenuSectionHeaderComponent> {
    return ComeToBaskSectionHeaderComponent;
  }

  getPrintFooterLayoutType(): PrintFooterLayoutType {
    return this.menuOptions?.printFooterLayout || PrintFooterLayoutType.AllPagesFixed;
  }

  getFooterType(): Type<ProductMenuFooterComponent> {
    return SwimlaneClassificationAndBadgesLegendFooterComponent;
  }

  wrappingSwimLaneOverflow(): WrappingSwimLaneOverflow {
    return WrappingSwimLaneOverflow.ThreeLanes;
  }

  getShowStrainTypes(): boolean {
    return false;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return false;
  }

  brandInlineWithProductName(): boolean {
    return false;
  }

  getUniqueClassificationList(): StrainType[] {
    return super.getUniqueClassificationList()?.sort(SortVariantUtils.genericStrainTypeSort);
  }

  getAllowedBadgeCount(): number {
    return 1;
  }

  getDefaultTitleSectionImageSrc(): string {
    return '/assets/images/come-to-bask/come-to-bask.png';
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.merriweatherRegular,
      FONT.merriweatherBold,
      FONT.merriweatherUltraBold,
    ];
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.StrainType, 0],
      [SectionColumnProductInfoType.ProductTitle, 1],
      [SectionColumnProductInfoType.Brand, 2],
      [SectionColumnProductInfoType.Badge, 3],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(4),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(5),
      [SectionColumnProductInfoType.QuantityAndSize, 6],
      [SectionColumnProductInfoType.Quantity, 7],
      [SectionColumnProductInfoType.Size, 8],
      [SectionColumnProductInfoType.VariantPrice, 9],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 10],
    ]);
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

}
