import { PrintCardMenu } from './print-card-menu';
import type { LocationPriceStream } from '../../enum/shared/location-price-stream';
import type { SectionWithProducts } from '../section/section-with-products';
import type { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { AssetLibraryType } from '../../enum/dto/asset-library-type.enum';
import { SpecificPriceWithoutDiscounts } from '../../enum/shared/specific-price-without-discounts.enum';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class PopsCannabisPrintCardMenu extends PrintCardMenu {

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.poppinsSemiBold,
      FONT.sourceSansProSemiBold,
      FONT.sourceSansProBlack,
      FONT.sourceSansProSemiBoldItalic
    ];
  }

  private popsPriceDecimalHelper(dec: string): string {
    return dec?.replace('.', '');
  }

  getPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel,
  ): string {
    const dec = super.getPriceDecimal(priceStream, locationId, s, rowVM, colVm);
    return this.popsPriceDecimalHelper(dec);
  }

  getSecondaryPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel
  ): string {
    const dec = super.getSecondaryPriceDecimal(priceStream, locationId, s, rowVM, colVm);
    return this.popsPriceDecimalHelper(dec);
  }

  getOriginalPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    sectionWithProducts: SectionWithProducts,
    sectionRowViewModel: SectionRowViewModel,
    sectionColumnViewModel: SectionColumnViewModel,
    specificPriceWithoutDiscounts: SpecificPriceWithoutDiscounts[]
  ): string {
    const dec = super.getOriginalPriceDecimal(
      priceStream,
      locationId,
      sectionWithProducts,
      sectionRowViewModel,
      sectionColumnViewModel,
      specificPriceWithoutDiscounts
    );
    return this.popsPriceDecimalHelper(dec);
  }

  shouldFetchVariantAssets(): boolean {
    return true;
  }

  /**
   * Brand asset should be displayed if available - no product fallback.
   */
  variantAssetTypeSortOrder(): string[] {
    return [
      AssetLibraryType.PrimaryBrand,
      AssetLibraryType.AlternateBrand,
      AssetLibraryType.Brand,
    ];
  }

}
