import { Type } from '@angular/core';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import { SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { Section } from '../section/section';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { StrainType } from '../../enum/dto/strain-classification.enum';
import { SortVariantUtils } from '../../../utils/sort-variant-utils';
import { StringUtils } from '../../../utils/string-utils';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { PrintFooterLayoutType } from '../../enum/shared/print-footer-layout-type.enum';
import { ProductMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/product-menu-footer.component';
import { PrintMenu } from '../print-menu';
import { exists } from '../../../functions/exists';
import { EmptyMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/empty-menu-footer/empty-menu-footer.component';
import { FONT, FontStyleSheet } from '../font-style-sheets';

/**
 * Viewport for fika print menu is 643 x 794. Makes the viewport 17cm by 21cm (6.69in by 8.27in) on an 8.5x11in page.
 */
export class FikaPrintMenu extends PrintMenu {

  getPageTopMargin(): string {
    return '1.37in';
  }

  getPageBottomMargin(): string {
    return '1.37in';
  }

  getPageLeftMargin(): string {
    return '0.9in';
  }

  getPageRightMargin(): string {
    return '0.9in';
  }

  getTitleSectionTopMargin(): string {
    return '0';
  }

  getTitleSectionBottomMargin(): string {
    return '0';
  }

  getThemeClass(): string {
    return 'fika-print-theme';
  }

  getHeaderType(): Type<MenuHeaderTitleImageComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.None;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getShowProductSubtitle(): boolean {
    return false;
  }

  brandInlineWithProductName(): boolean {
    return true;
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.ProductTitle, 1],
      [SectionColumnProductInfoType.Badge, 2],
      [SectionColumnProductInfoType.StrainType, 3],
      [SectionColumnProductInfoType.Brand, 4],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(5),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(6),
      [SectionColumnProductInfoType.QuantityAndSize, 7],
      [SectionColumnProductInfoType.Quantity, 8],
      [SectionColumnProductInfoType.Size, 9],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 10],
      [SectionColumnProductInfoType.VariantPrice, 11],
    ]);
  }

  getShouldInflateColumnHeaderForRow(
    s: Section,
    col: SectionColumnViewModel,
    rows: SectionRowViewModel[]
  ): boolean {
    const classification = col?.columnType === SectionColumnProductInfoType.StrainType;
    return !classification || (classification && exists(col?.columnTitle));
  }

  getShouldInflateColumnForRow(
    s: Section,
    col: SectionColumnViewModel,
    row: SectionRowViewModel
  ): boolean {
    const badge = col?.columnType === SectionColumnProductInfoType.Badge;
    return !badge || (badge && exists(row?.hasBadges()));
  }

  getUniqueClassificationList(): StrainType[] {
    return super.getUniqueClassificationList()
      ?.filter(s => s !== StrainType.SativaDominant && s !== StrainType.IndicaDominant)
      ?.sort(SortVariantUtils.genericStrainTypeSort);
  }

  getPriceColumnTitle(col: SectionColumnViewModel): string {
    return StringUtils.removeWhiteSpace(super.getPriceColumnTitle(col));
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.ptMonoBold,
      FONT.poppinsSemiBold,
      FONT.poppinsMedium,
      FONT.poppinsBold,
      FONT.poppinsItalic
    ];
  }

  getPrintFooterLayoutType(): PrintFooterLayoutType {
    return this.menuOptions?.printFooterLayout || PrintFooterLayoutType.None;
  }

  getFooterType(): Type<ProductMenuFooterComponent> {
    return EmptyMenuFooterComponent;
  }

}
