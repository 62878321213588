import { Type } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { ReportMenu } from './report-menu';
import { MenuSectionHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section-header/menu-section-header.component';
import { OrderReviewSectionHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section-header/order-review-section-header/order-review-section-header.component';
import { SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { Section } from '../section/section';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { ReportNewProductsSection } from '../section/report/report-new-products-section';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class OrderReviewReportMenu extends ReportMenu {

  getThemeClass(): string {
    return 'order-review-theme';
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.poppinsBold,
      FONT.poppinsMedium
    ];
  }

  getPageBottomMargin(): string {
    return '0.25in';
  }

  getPageLeftMargin(): string {
    return '0.25in';
  }

  getPageRightMargin(): string {
    return '0.25in';
  }

  getPageTopMargin(): string {
    return '0.25in';
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.AllPages;
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getSectionsHeaderType(): Type<MenuSectionHeaderComponent> {
    return OrderReviewSectionHeaderComponent;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getShowProductSubtitle(): boolean {
    return false;
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  shouldFetchVariantAssets(): boolean {
    return true;
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getShouldInflateColumnHeaderForRow(s: Section, col: SectionColumnViewModel, rows: SectionRowViewModel[]): boolean {
    const assetCol = col?.columnType === SectionColumnProductInfoType.Asset;
    const badgeCol = col?.columnType === SectionColumnProductInfoType.Badge;
    if (s instanceof ReportNewProductsSection && (assetCol || badgeCol)) {
      return false;
    }
    return super.getShouldInflateColumnHeaderForRow(s, col, rows);
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.ProductTitle, 0],
      [SectionColumnProductInfoType.Brand, 1],
      [SectionColumnProductInfoType.StrainType, 2],
      [SectionColumnProductInfoType.Badge, 3],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(4),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(5),
      [SectionColumnProductInfoType.Stock, 6],
      [SectionColumnProductInfoType.QuantityAndSize, 7],
      [SectionColumnProductInfoType.Quantity, 8],
      [SectionColumnProductInfoType.Size, 9],
      [SectionColumnProductInfoType.VariantPrice, 10],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 11],
    ]);
  }

}
