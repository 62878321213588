import { FONT, FontStyleSheet } from '../font-style-sheets';
import { PrintCardMenu } from './print-card-menu';

export class HoneyHazePrintCardMenu extends PrintCardMenu {

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.poppinsExtraBold,
      FONT.poppinsRegular,
    ];
  }

}
