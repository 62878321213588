import { AssetLibraryType } from '../../enum/dto/asset-library-type.enum';
import { FONT, FontStyleSheet } from '../font-style-sheets';
import { PrintCardMenu } from './print-card-menu';

export class NycdPrintCardMenu extends PrintCardMenu {

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.interBold,
      FONT.interMedium,
      FONT.interBlack,
      FONT.interSemiBoldItalic
    ];
  }

  shouldFetchVariantAssets(): boolean {
    return true;
  }

  override variantAssetTypeSortOrder(): string[] {
    return [
      AssetLibraryType.PrimaryBrand,
      AssetLibraryType.AlternateBrand,
      AssetLibraryType.Brand
    ];
  }

}
