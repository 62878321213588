import { Type } from '@angular/core';
import { ProductMenu } from '../product-menu';
import { ProductMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/product-menu-footer.component';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import type { SectionWithProducts } from '../section/section-with-products';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class WeedMenu extends ProductMenu {

  getThemeClass(): string {
    return 'weed-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getFooterType(): Type<ProductMenuFooterComponent> {
    return ProductMenuFooterComponent;
  }

  getShowFooter(): boolean {
    return false;
  }

  getSectionWrapperClass(...args): string {
    if (this.isPortrait()) {
      return 'weed-section-container-portrait';
    } else {
      return 'weed-section-container';
    }
  }

  getPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel,
  ): string {
    if (!this.getDropDecimal()) {
      const price = this.getVariantPricing(priceStream, locationId, s, rowVM, colVm, false, true);
      const dec = price.split('.')?.[1];
      // decimals and formatting are included. ie: $1000.00
      if (price === '-' || price.length > 7 || dec === '') {
        return '';
      } else {
        return dec;
      }
    } else {
      return '';
    }
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return false;
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.windsorBold,
      FONT.proximaNovaBold,
      FONT.proximaNovaSemiBold,
      FONT.proximaNovaRegular,
      FONT.proximaNovaBoldItalic,
      FONT.proximaNovaExtraBold
    ];
  }

}
