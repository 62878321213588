type FontStyle = 'normal' | 'italic';
type FontWeight = '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900' | '1000';

export type FontStyleSheet = {
  fontFamily: string,
  fontWeight?: FontWeight,
  fontStyle?: FontStyle
}

export const FONT = {
  openSansRegular: {
    fontFamily: 'open-sans',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  openSansSemiBold: {
    fontFamily: 'open-sans',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  openSansBold: {
    fontFamily: 'open-sans',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  openSansBoldExtra: {
    fontFamily: 'open-sans',
    fontWeight: '800',
    fontStyle: 'normal'
  } as FontStyleSheet,

  bebasNeueProRegular: {
    fontFamily: 'bebas-neue-pro',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  bebasNeueProBold: {
    fontFamily: 'bebas-neue-pro',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,

  myriadProRegular: {
    fontFamily: 'myriad-pro',
    fontStyle: 'normal',
    fontWeight: '400'
  } as FontStyleSheet,
  myriadProSemiBold: {
    fontFamily: 'myriad-pro',
    fontStyle: 'normal',
    fontWeight: '600'
  } as FontStyleSheet,
  myriadProBold: {
    fontFamily: 'myriad-pro',
    fontStyle: 'normal',
    fontWeight: '700'
  } as FontStyleSheet,

  courierRegular: {
    fontFamily: 'courier-std',
    fontStyle: 'normal',
    fontWeight: '400'
  } as FontStyleSheet,
  courierBold: {
    fontFamily: 'courier-std',
    fontStyle: 'normal',
    fontWeight: '700'
  } as FontStyleSheet,

  playfairDisplayBold: {
    fontFamily: 'playfair-display',
    fontStyle: 'normal',
    fontWeight: '700'
  } as FontStyleSheet,

  ralewayBold: {
    fontFamily: 'raleway',
    fontStyle: 'normal',
    fontWeight: '700'
  } as FontStyleSheet,
  ralewayMedium: {
    fontFamily: 'raleway',
    fontStyle: 'normal',
    fontWeight: '500'
  } as FontStyleSheet,
  ralewayItalic: {
    fontFamily: 'raleway',
    fontStyle: 'italic',
    fontWeight: '400'
  } as FontStyleSheet,
  acuminProExtraCondensedRegular: {
    fontFamily: 'acumin-pro-extra-condensed',
    fontStyle: 'normal',
    fontWeight: '400'
  } as FontStyleSheet,
  acuminProWideBold: {
    fontFamily: 'acumin-pro-wide',
    fontStyle: 'normal',
    fontWeight: '700'
  } as FontStyleSheet,
  acuminProWideSemibold: {
    fontFamily: 'acumin-pro-wide',
    fontStyle: 'normal',
    fontWeight: '600'
  } as FontStyleSheet,
  acuminProWideRegular: {
    fontFamily: 'acumin-pro-wide',
    fontStyle: 'normal',
    fontWeight: '400'
  } as FontStyleSheet,

  facileSans: {
    fontFamily: 'Facile-Sans',
    fontStyle: 'normal'
  } as FontStyleSheet,

  banksMiles: {
    fontFamily: 'Banks-Miles',
    fontStyle: 'normal',
    fontWeight: '400'
  } as FontStyleSheet,
  banksMilesMedium: {
    fontFamily: 'Banks-Miles',
    fontStyle: 'normal',
    fontWeight: '600'
  } as FontStyleSheet,
  banksMilesBold: {
    fontFamily: 'Banks-Miles',
    fontStyle: 'normal',
    fontWeight: '700'
  } as FontStyleSheet,

  merriweatherRegular: {
    fontFamily: 'merriweather',
    fontStyle: 'normal',
    fontWeight: '400'
  } as FontStyleSheet,
  merriweatherBold: {
    fontFamily: 'merriweather',
    fontStyle: 'normal',
    fontWeight: '700'
  } as FontStyleSheet,
  merriweatherUltraBold: {
    fontFamily: 'merriweather',
    fontStyle: 'normal',
    fontWeight: '900'
  } as FontStyleSheet,

  oswaldLight: {
    fontFamily: 'oswald',
    fontStyle: 'normal',
    fontWeight: '300'
  } as FontStyleSheet,
  oswaldMedium: {
    fontFamily: 'oswald',
    fontStyle: 'normal',
    fontWeight: '400'
  } as FontStyleSheet,
  oswaldSemiBold: {
    fontFamily: 'oswald',
    fontStyle: 'normal',
    fontWeight: '500'
  } as FontStyleSheet,
  oswaldBold: {
    fontFamily: 'oswald',
    fontStyle: 'normal',
    fontWeight: '600'
  } as FontStyleSheet,

  robotoRegular: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400'
  } as FontStyleSheet,
  robotoMedium: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500'
  } as FontStyleSheet,
  robotoMediumExtra: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '600'
  } as FontStyleSheet,
  robotoBold: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700'
  } as FontStyleSheet,
  robotoBlack: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '900'
  } as FontStyleSheet,
  robotoBlackExtra: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '1000'
  } as FontStyleSheet,

  robotoRegularItalic: {
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    fontWeight: '400'
  } as FontStyleSheet,

  poppinsThin: {
    fontFamily: 'poppins',
    fontWeight: '100'
  } as FontStyleSheet,
  poppinsExtraLight: {
    fontFamily: 'poppins',
    fontWeight: '200'
  } as FontStyleSheet,
  poppinsRegular: {
    fontFamily: 'poppins',
    fontWeight: '400'
  } as FontStyleSheet,
  poppinsMedium: {
    fontFamily: 'poppins',
    fontWeight: '500'
  } as FontStyleSheet,
  poppinsSemiBold: {
    fontFamily: 'poppins',
    fontWeight: '600'
  } as FontStyleSheet,
  poppinsBold: {
    fontFamily: 'poppins',
    fontWeight: '700'
  } as FontStyleSheet,
  poppinsExtraBold: {
    fontFamily: 'poppins',
    fontWeight: '800',
  } as FontStyleSheet,
  poppinsItalic: {
    fontFamily: 'poppins',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  poppinsSemiBoldItalic: {
    fontFamily: 'poppins',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  poppinsBoldItalic: {
    fontFamily: 'poppins',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,

  ptMonoBold: {
    fontFamily: 'pt-mono',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,

  futuraPtBook: {
    fontFamily: 'futura-pt',
    fontWeight: '400'
  } as FontStyleSheet,
  futuraPtDemi: {
    fontFamily: 'futura-pt',
    fontWeight: '600'
  } as FontStyleSheet,
  futuraPtMedium: {
    fontFamily: 'futura-pt',
    fontWeight: '500'
  } as FontStyleSheet,
  futuraPtBold: {
    fontFamily: 'futura-pt-bold',
    fontWeight: '700'
  } as FontStyleSheet,
  futuraPtHeavy: {
    fontFamily: 'futura-pt',
    fontWeight: '700'
  } as FontStyleSheet,
  futuraPtExtraBold: {
    fontFamily: 'futura-pt',
    fontWeight: '800'
  } as FontStyleSheet,

  // Adding missing fonts from SCSS
  poppinsLight: {
    fontFamily: 'poppins',
    fontWeight: '300'
  } as FontStyleSheet,
  poppinsBlack: {
    fontFamily: 'poppins',
    fontWeight: '900'
  } as FontStyleSheet,

  playfairDisplayRegular: {
    fontFamily: 'playfair-display',
    fontStyle: 'normal',
    fontWeight: '400'
  } as FontStyleSheet,
  playfairDisplayItalic: {
    fontFamily: 'playfair-display',
    fontStyle: 'italic',
    fontWeight: '400'
  } as FontStyleSheet,
  playfairDisplayBoldItalic: {
    fontFamily: 'playfair-display',
    fontStyle: 'italic',
    fontWeight: '700'
  } as FontStyleSheet,
  playfairDisplayBlack: {
    fontFamily: 'playfair-display',
    fontStyle: 'normal',
    fontWeight: '900'
  } as FontStyleSheet,
  playfairDisplayBlackItalic: {
    fontFamily: 'playfair-display',
    fontStyle: 'italic',
    fontWeight: '900'
  } as FontStyleSheet,

  // Spectral
  spectralExtraLight: {
    fontFamily: 'Spectral',
    fontWeight: '200',
    fontStyle: 'normal'
  } as FontStyleSheet,
  spectralExtraLightItalic: {
    fontFamily: 'Spectral',
    fontWeight: '200',
    fontStyle: 'italic'
  } as FontStyleSheet,
  spectralLight: {
    fontFamily: 'Spectral',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  spectralLightItalic: {
    fontFamily: 'Spectral',
    fontWeight: '300',
    fontStyle: 'italic'
  } as FontStyleSheet,
  spectralRegular: {
    fontFamily: 'Spectral',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  spectralItalic: {
    fontFamily: 'Spectral',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  spectralMedium: {
    fontFamily: 'Spectral',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  spectralMediumItalic: {
    fontFamily: 'Spectral',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  spectralSemiBold: {
    fontFamily: 'Spectral',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  spectralSemiBoldItalic: {
    fontFamily: 'Spectral',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  spectralBold: {
    fontFamily: 'Spectral',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  spectralBoldItalic: {
    fontFamily: 'Spectral',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  spectralExtraBold: {
    fontFamily: 'Spectral',
    fontWeight: '800',
    fontStyle: 'normal'
  } as FontStyleSheet,
  spectralExtraBoldItalic: {
    fontFamily: 'Spectral',
    fontWeight: '800',
    fontStyle: 'italic'
  } as FontStyleSheet,

  interThin: {
    fontFamily: 'Inter',
    fontWeight: '100',
    fontStyle: 'normal'
  } as FontStyleSheet,
  interThinItalic: {
    fontFamily: 'Inter',
    fontWeight: '100',
    fontStyle: 'italic'
  } as FontStyleSheet,
  interExtraLight: {
    fontFamily: 'Inter',
    fontWeight: '200',
    fontStyle: 'normal'
  } as FontStyleSheet,
  interExtraLightItalic: {
    fontFamily: 'Inter',
    fontWeight: '200',
    fontStyle: 'italic'
  } as FontStyleSheet,
  interLight: {
    fontFamily: 'Inter',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  interLightItalic: {
    fontFamily: 'Inter',
    fontWeight: '300',
    fontStyle: 'italic'
  } as FontStyleSheet,
  interRegular: {
    fontFamily: 'Inter',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  interItalic: {
    fontFamily: 'Inter',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  interMedium: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  interMediumItalic: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  interSemiBold: {
    fontFamily: 'Inter',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  interSemiBoldItalic: {
    fontFamily: 'Inter',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  interBold: {
    fontFamily: 'Inter',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  interBoldItalic: {
    fontFamily: 'Inter',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  interExtraBold: {
    fontFamily: 'Inter',
    fontWeight: '800',
    fontStyle: 'normal'
  } as FontStyleSheet,
  interExtraBoldItalic: {
    fontFamily: 'Inter',
    fontWeight: '800',
    fontStyle: 'italic'
  } as FontStyleSheet,
  interBlack: {
    fontFamily: 'Inter',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,
  interBlackItalic: {
    fontFamily: 'Inter',
    fontWeight: '900',
    fontStyle: 'italic'
  } as FontStyleSheet,

  ptMonoRegular: {
    fontFamily: 'pt-mono',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,

  karlaExtraLight: {
    fontFamily: 'Karla',
    fontWeight: '200',
    fontStyle: 'normal'
  } as FontStyleSheet,
  karlaExtraLightItalic: {
    fontFamily: 'Karla',
    fontWeight: '200',
    fontStyle: 'italic'
  } as FontStyleSheet,
  karlaLight: {
    fontFamily: 'Karla',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  karlaLightItalic: {
    fontFamily: 'Karla',
    fontWeight: '300',
    fontStyle: 'italic'
  } as FontStyleSheet,
  karlaRegular: {
    fontFamily: 'Karla',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  karlaRegularItalic: {
    fontFamily: 'Karla',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  karlaMedium: {
    fontFamily: 'Karla',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  karlaMediumItalic: {
    fontFamily: 'Karla',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  karlaSemiBold: {
    fontFamily: 'Karla',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  karlaSemiBoldItalic: {
    fontFamily: 'Karla',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  karlaBold: {
    fontFamily: 'Karla',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  karlaBoldItalic: {
    fontFamily: 'Karla',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  karlaExtraBold: {
    fontFamily: 'Karla',
    fontWeight: '800',
    fontStyle: 'normal'
  } as FontStyleSheet,
  karlaExtraBoldItalic: {
    fontFamily: 'Karla',
    fontWeight: '800',
    fontStyle: 'italic'
  } as FontStyleSheet,

  ramaGothicMRegular: {
    fontFamily: 'RamaGothicM',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  ramaGothicMSemiBold: {
    fontFamily: 'RamaGothicM',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  ramaGothicMBold: {
    fontFamily: 'RamaGothicM',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  ramaGothicMBlack: {
    fontFamily: 'RamaGothicM',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,

  metropolisThin: {
    fontFamily: 'Metropolis',
    fontWeight: '100',
    fontStyle: 'normal'
  } as FontStyleSheet,
  metropolisThinItalic: {
    fontFamily: 'Metropolis',
    fontWeight: '100',
    fontStyle: 'italic'
  } as FontStyleSheet,
  metropolisExtraLight: {
    fontFamily: 'Metropolis',
    fontWeight: '200',
    fontStyle: 'normal'
  } as FontStyleSheet,
  metropolisExtraLightItalic: {
    fontFamily: 'Metropolis',
    fontWeight: '200',
    fontStyle: 'italic'
  } as FontStyleSheet,
  metropolisLight: {
    fontFamily: 'Metropolis',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  metropolisLightItalic: {
    fontFamily: 'Metropolis',
    fontWeight: '300',
    fontStyle: 'italic'
  } as FontStyleSheet,
  metropolisRegular: {
    fontFamily: 'Metropolis',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  metropolisRegularItalic: {
    fontFamily: 'Metropolis',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  metropolisMedium: {
    fontFamily: 'Metropolis',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  metropolisMediumItalic: {
    fontFamily: 'Metropolis',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  metropolisSemiBold: {
    fontFamily: 'Metropolis',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  metropolisSemiBoldItalic: {
    fontFamily: 'Metropolis',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  metropolisBold: {
    fontFamily: 'Metropolis',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  metropolisBoldItalic: {
    fontFamily: 'Metropolis',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  metropolisExtraBold: {
    fontFamily: 'Metropolis',
    fontWeight: '800',
    fontStyle: 'normal'
  } as FontStyleSheet,
  metropolisExtraBoldItalic: {
    fontFamily: 'Metropolis',
    fontWeight: '800',
    fontStyle: 'italic'
  } as FontStyleSheet,
  metropolisBlack: {
    fontFamily: 'Metropolis',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,
  metropolisBlackItalic: {
    fontFamily: 'Metropolis',
    fontWeight: '900',
    fontStyle: 'italic'
  } as FontStyleSheet,

  neueHaasGroteskDisplayLight: {
    fontFamily: 'NeueHaasGroteskDisplay',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  neueHaasGroteskDisplayLightItalic: {
    fontFamily: 'NeueHaasGroteskDisplay',
    fontWeight: '300',
    fontStyle: 'italic'
  } as FontStyleSheet,
  neueHaasGroteskDisplayRegular: {
    fontFamily: 'NeueHaasGroteskDisplay',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  neueHaasGroteskDisplayRegularItalic: {
    fontFamily: 'NeueHaasGroteskDisplay',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  neueHaasGroteskDisplayMedium: {
    fontFamily: 'NeueHaasGroteskDisplay',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  neueHaasGroteskDisplayMediumItalic: {
    fontFamily: 'NeueHaasGroteskDisplay',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  neueHaasGroteskDisplaySemiBold: {
    fontFamily: 'NeueHaasGroteskDisplay',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  neueHaasGroteskDisplaySemiBoldItalic: {
    fontFamily: 'NeueHaasGroteskDisplay',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  neueHaasGroteskDisplayBold: {
    fontFamily: 'NeueHaasGroteskDisplay',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  neueHaasGroteskDisplayBoldItalic: {
    fontFamily: 'NeueHaasGroteskDisplay',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  neueHaasGroteskDisplayBlack: {
    fontFamily: 'NeueHaasGroteskDisplay',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,
  neueHaasGroteskDisplayBlackItalic: {
    fontFamily: 'NeueHaasGroteskDisplay',
    fontWeight: '900',
    fontStyle: 'italic'
  } as FontStyleSheet,

  indivisibleRegular: {
    fontFamily: 'indivisible',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  indivisibleRegularItalic: {
    fontFamily: 'indivisible',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  indivisibleMediumItalic: {
    fontFamily: 'indivisible',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  indivisibleSemiBold: {
    fontFamily: 'indivisible',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  indivisibleSemiBoldItalic: {
    fontFamily: 'indivisible',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  indivisibleBold: {
    fontFamily: 'indivisible',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  indivisibleBoldItalic: {
    fontFamily: 'indivisible',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  indivisibleBlack: {
    fontFamily: 'indivisible',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,
  indivisibleBlackItalic: {
    fontFamily: 'indivisible',
    fontWeight: '900',
    fontStyle: 'italic'
  } as FontStyleSheet,

  nunitoThin: {
    fontFamily: 'nunito',
    fontWeight: '100',
    fontStyle: 'normal'
  } as FontStyleSheet,
  nunitoThinItalic: {
    fontFamily: 'nunito',
    fontWeight: '100',
    fontStyle: 'italic'
  } as FontStyleSheet,
  nunitoExtraLight: {
    fontFamily: 'nunito',
    fontWeight: '200',
    fontStyle: 'normal'
  } as FontStyleSheet,
  nunitoExtraLightItalic: {
    fontFamily: 'nunito',
    fontWeight: '200',
    fontStyle: 'italic'
  } as FontStyleSheet,
  nunitoLight: {
    fontFamily: 'nunito',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  nunitoLightItalic: {
    fontFamily: 'nunito',
    fontWeight: '300',
    fontStyle: 'italic'
  } as FontStyleSheet,
  nunitoRegular: {
    fontFamily: 'nunito',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  nunitoRegularItalic: {
    fontFamily: 'nunito',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  nunitoMedium: {
    fontFamily: 'nunito',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  nunitoMediumItalic: {
    fontFamily: 'nunito',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  nunitoSemiBold: {
    fontFamily: 'nunito',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  nunitoSemiBoldItalic: {
    fontFamily: 'nunito',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  nunitoBold: {
    fontFamily: 'nunito',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  nunitoBoldItalic: {
    fontFamily: 'nunito',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  nunitoExtraBold: {
    fontFamily: 'nunito',
    fontWeight: '800',
    fontStyle: 'normal'
  } as FontStyleSheet,
  nunitoExtraBoldItalic: {
    fontFamily: 'nunito',
    fontWeight: '800',
    fontStyle: 'italic'
  } as FontStyleSheet,
  nunitoBlack: {
    fontFamily: 'nunito',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,
  nunitoBlackItalic: {
    fontFamily: 'nunito',
    fontWeight: '900',
    fontStyle: 'italic'
  } as FontStyleSheet,

  nunitoSansThin: {
    fontFamily: 'nunito-sans',
    fontWeight: '100',
    fontStyle: 'normal'
  } as FontStyleSheet,
  nunitoSansThinItalic: {
    fontFamily: 'nunito-sans',
    fontWeight: '100',
    fontStyle: 'italic'
  } as FontStyleSheet,
  nunitoSansExtraLight: {
    fontFamily: 'nunito-sans',
    fontWeight: '200',
    fontStyle: 'normal'
  } as FontStyleSheet,
  nunitoSansExtraLightItalic: {
    fontFamily: 'nunito-sans',
    fontWeight: '200',
    fontStyle: 'italic'
  } as FontStyleSheet,
  nunitoSansLight: {
    fontFamily: 'nunito-sans',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  nunitoSansLightItalic: {
    fontFamily: 'nunito-sans',
    fontWeight: '300',
    fontStyle: 'italic'
  } as FontStyleSheet,
  nunitoSansRegular: {
    fontFamily: 'nunito-sans',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  nunitoSansRegularItalic: {
    fontFamily: 'nunito-sans',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  nunitoSansMedium: {
    fontFamily: 'nunito-sans',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  nunitoSansMediumItalic: {
    fontFamily: 'nunito-sans',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  nunitoSansSemiBold: {
    fontFamily: 'nunito-sans',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  nunitoSansSemiBoldItalic: {
    fontFamily: 'nunito-sans',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  nunitoSansBold: {
    fontFamily: 'nunito-sans',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  nunitoSansBoldItalic: {
    fontFamily: 'nunito-sans',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  nunitoSansExtraBold: {
    fontFamily: 'nunito-sans',
    fontWeight: '800',
    fontStyle: 'normal'
  } as FontStyleSheet,
  nunitoSansExtraBoldItalic: {
    fontFamily: 'nunito-sans',
    fontWeight: '800',
    fontStyle: 'italic'
  } as FontStyleSheet,
  nunitoSansBlack: {
    fontFamily: 'nunito-sans',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,
  nunitoSansBlackItalic: {
    fontFamily: 'nunito-sans',
    fontWeight: '900',
    fontStyle: 'italic'
  } as FontStyleSheet,

  workSansThin: {
    fontFamily: 'Work-Sans',
    fontWeight: '100',
    fontStyle: 'normal'
  } as FontStyleSheet,
  workSansThinItalic: {
    fontFamily: 'Work-Sans',
    fontWeight: '100',
    fontStyle: 'italic'
  } as FontStyleSheet,
  workSansExtraLight: {
    fontFamily: 'Work-Sans',
    fontWeight: '200',
    fontStyle: 'normal'
  } as FontStyleSheet,
  workSansExtraLightItalic: {
    fontFamily: 'Work-Sans',
    fontWeight: '200',
    fontStyle: 'italic'
  } as FontStyleSheet,
  workSansLight: {
    fontFamily: 'Work-Sans',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  workSansLightItalic: {
    fontFamily: 'Work-Sans',
    fontWeight: '300',
    fontStyle: 'italic'
  } as FontStyleSheet,
  workSansRegular: {
    fontFamily: 'Work-Sans',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  workSansRegularItalic: {
    fontFamily: 'Work-Sans',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  workSansMedium: {
    fontFamily: 'Work-Sans',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  workSansMediumItalic: {
    fontFamily: 'Work-Sans',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  workSansSemiBold: {
    fontFamily: 'Work-Sans',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  workSansSemiBoldItalic: {
    fontFamily: 'Work-Sans',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  workSansBold: {
    fontFamily: 'Work-Sans',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  workSansBoldItalic: {
    fontFamily: 'Work-Sans',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  workSansExtraBold: {
    fontFamily: 'Work-Sans',
    fontWeight: '800',
    fontStyle: 'normal'
  } as FontStyleSheet,
  workSansExtraBoldItalic: {
    fontFamily: 'Work-Sans',
    fontWeight: '800',
    fontStyle: 'italic'
  } as FontStyleSheet,
  workSansBlack: {
    fontFamily: 'Work-Sans',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,
  workSansBlackItalic: {
    fontFamily: 'Work-Sans',
    fontWeight: '900',
    fontStyle: 'italic'
  } as FontStyleSheet,

  tzimmiesThin: {
    fontFamily: 'tzimmes',
    fontWeight: '100',
    fontStyle: 'normal'
  } as FontStyleSheet,
  tzimmiesThinItalic: {
    fontFamily: 'tzimmes',
    fontWeight: '100',
    fontStyle: 'italic'
  } as FontStyleSheet,
  tzimmiesExtraLight: {
    fontFamily: 'tzimmes',
    fontWeight: '200',
    fontStyle: 'normal'
  } as FontStyleSheet,
  tzimmiesExtraLightItalic: {
    fontFamily: 'tzimmes',
    fontWeight: '200',
    fontStyle: 'italic'
  } as FontStyleSheet,
  tzimmiesLight: {
    fontFamily: 'tzimmes',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  tzimmiesLightItalic: {
    fontFamily: 'tzimmes',
    fontWeight: '300',
    fontStyle: 'italic'
  } as FontStyleSheet,
  tzimmiesRegular: {
    fontFamily: 'tzimmes',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  tzimmiesRegularItalic: {
    fontFamily: 'tzimmes',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  tzimmiesMedium: {
    fontFamily: 'tzimmes',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  tzimmiesMediumItalic: {
    fontFamily: 'tzimmes',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  tzimmiesSemiBold: {
    fontFamily: 'tzimmes',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  tzimmiesSemiBoldItalic: {
    fontFamily: 'tzimmes',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  tzimmiesBold: {
    fontFamily: 'tzimmes',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  tzimmiesBoldItalic: {
    fontFamily: 'tzimmes',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  tzimmiesUltraBold: {
    fontFamily: 'tzimmes',
    fontWeight: '800',
    fontStyle: 'normal'
  } as FontStyleSheet,
  tzimmiesUltraBoldItalic: {
    fontFamily: 'tzimmes',
    fontWeight: '800',
    fontStyle: 'italic'
  } as FontStyleSheet,
  tzimmiesExtraBold: {
    fontFamily: 'tzimmes',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,
  tzimmiesExtraBoldItalic: {
    fontFamily: 'tzimmes',
    fontWeight: '900',
    fontStyle: 'italic'
  } as FontStyleSheet,

  gilroyThin: {
    fontFamily: 'Gilroy',
    fontWeight: '100',
    fontStyle: 'normal'
  } as FontStyleSheet,
  gilroyThinItalic: {
    fontFamily: 'Gilroy',
    fontWeight: '100',
    fontStyle: 'italic'
  } as FontStyleSheet,
  gilroyExtraLight: {
    fontFamily: 'Gilroy',
    fontWeight: '200',
    fontStyle: 'normal'
  } as FontStyleSheet,
  gilroyExtraLightItalic: {
    fontFamily: 'Gilroy',
    fontWeight: '200',
    fontStyle: 'italic'
  } as FontStyleSheet,
  gilroyLight: {
    fontFamily: 'Gilroy',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  gilroyLightItalic: {
    fontFamily: 'Gilroy',
    fontWeight: '300',
    fontStyle: 'italic'
  } as FontStyleSheet,
  gilroyRegular: {
    fontFamily: 'Gilroy',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  gilroyItalic: {
    fontFamily: 'Gilroy',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  gilroyMedium: {
    fontFamily: 'Gilroy',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  gilroyMediumItalic: {
    fontFamily: 'Gilroy',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  gilroySemiBold: {
    fontFamily: 'Gilroy',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  gilroySemiBoldItalic: {
    fontFamily: 'Gilroy',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  gilroyBold: {
    fontFamily: 'Gilroy',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  gilroyBoldItalic: {
    fontFamily: 'Gilroy',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  gilroyExtraBold: {
    fontFamily: 'Gilroy',
    fontWeight: '800',
    fontStyle: 'normal'
  } as FontStyleSheet,
  gilroyExtraBoldItalic: {
    fontFamily: 'Gilroy',
    fontWeight: '800',
    fontStyle: 'italic'
  } as FontStyleSheet,
  gilroyHeavy: {
    fontFamily: 'Gilroy',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,
  gilroyHeavyItalic: {
    fontFamily: 'Gilroy',
    fontWeight: '900',
    fontStyle: 'italic'
  } as FontStyleSheet,

  bebasNeueProThin: {
    fontFamily: 'bebas-neue-pro',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  bebasNeueProThinItalic: {
    fontFamily: 'bebas-neue-pro',
    fontWeight: '300',
    fontStyle: 'italic'
  } as FontStyleSheet,
  bebasNeueProItalic: {
    fontFamily: 'bebas-neue-pro',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  bebasNeueProMiddle: {
    fontFamily: 'bebas-neue-pro',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  bebasNeueProMiddleItalic: {
    fontFamily: 'bebas-neue-pro',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  bebasNeueProBoldItalic: {
    fontFamily: 'bebas-neue-pro',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  bebasNeueProExpandedBold: {
    fontFamily: 'bebas-neue-pro-expanded',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  bebasNeueProExpandedBoldItalic: {
    fontFamily: 'bebas-neue-pro-expanded',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,

  pragmaticaLight: {
    fontFamily: 'pragmatica',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  pragmaticaBook: {
    fontFamily: 'pragmatica',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  pragmaticaExtendedMedium: {
    fontFamily: 'pragmatica-extended',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,

  apparatMedium: {
    fontFamily: 'apparat',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  apparatMediumItalic: {
    fontFamily: 'apparat',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  apparatSemiBold: {
    fontFamily: 'apparat',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  apparatSemiBoldItalic: {
    fontFamily: 'apparat',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  apparatBold: {
    fontFamily: 'apparat',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  apparatBoldItalic: {
    fontFamily: 'apparat',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  apparatExtraBold: {
    fontFamily: 'apparat',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  apparatExtraBoldItalic: {
    fontFamily: 'apparat',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,

  soleilNormal: {
    fontFamily: 'Soleil',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  soleilItalic: {
    fontFamily: 'Soleil',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  soleilSemiBold: {
    fontFamily: 'Soleil',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  soleilSemiBoldItalic: {
    fontFamily: 'Soleil',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  soleilBold: {
    fontFamily: 'Soleil',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  soleilBoldItalic: {
    fontFamily: 'Soleil',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  soleilExtraBoldNormal: {
    fontFamily: 'Soleil',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  soleilExtraBoldItalic: {
    fontFamily: 'Soleil',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,

  p22MackinacProBold: {
    fontFamily: 'P22-Mackinac-Pro',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,

  windsorBold: {
    fontFamily: 'Windsor-Bold',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,

  bigNoodle: {
    fontFamily: 'Big-Noodle-Titling',
    fontStyle: 'normal'
  } as FontStyleSheet,

  dongleBold: {
    fontFamily: 'Dongle',
    fontWeight: '700'
  } as FontStyleSheet,
  dongleRegular: {
    fontFamily: 'Dongle',
    fontWeight: '400'
  } as FontStyleSheet,
  dongleLight: {
    fontFamily: 'Dongle',
    fontWeight: '300'
  } as FontStyleSheet,

  ubuntuBold: {
    fontFamily: 'Ubuntu-Titled',
    fontStyle: 'normal'
  } as FontStyleSheet,

  montHeavy: {
    fontFamily: 'Mont-Heavy',
    fontStyle: 'normal'
  } as FontStyleSheet,
  montExtraLight: {
    fontFamily: 'Mont-ExtraLight',
    fontWeight: '200'
  } as FontStyleSheet,

  edsMarketNarrowSlant: {
    fontFamily: 'eds-market-narrow-slant',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,

  shelbyRegular: {
    fontFamily: 'shelby',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  shelbyBold: {
    fontFamily: 'shelby',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,

  arialRegular: {
    fontFamily: 'Arial',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  arialSemiBold: {
    fontFamily: 'Arial',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  arialBold: {
    fontFamily: 'Arial',
    fontWeight: '800',
    fontStyle: 'normal'
  } as FontStyleSheet,

  // Archivo
  archivoThin: {
    fontFamily: 'Archivo',
    fontWeight: '100',
    fontStyle: 'normal'
  } as FontStyleSheet,
  archivoThinItalic: {
    fontFamily: 'Archivo',
    fontWeight: '100',
    fontStyle: 'italic'
  } as FontStyleSheet,
  archivoExtraLight: {
    fontFamily: 'Archivo',
    fontWeight: '200',
    fontStyle: 'normal'
  } as FontStyleSheet,
  archivoExtraLightItalic: {
    fontFamily: 'Archivo',
    fontWeight: '200',
    fontStyle: 'italic'
  } as FontStyleSheet,
  archivoLight: {
    fontFamily: 'Archivo',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  archivoLightItalic: {
    fontFamily: 'Archivo',
    fontWeight: '300',
    fontStyle: 'italic'
  } as FontStyleSheet,
  archivoRegular: {
    fontFamily: 'Archivo',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  archivoItalic: {
    fontFamily: 'Archivo',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  archivoMedium: {
    fontFamily: 'Archivo',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  archivoMediumItalic: {
    fontFamily: 'Archivo',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  archivoSemiBold: {
    fontFamily: 'Archivo',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  archivoSemiBoldItalic: {
    fontFamily: 'Archivo',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  archivoBold: {
    fontFamily: 'Archivo',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  archivoBoldItalic: {
    fontFamily: 'Archivo',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  archivoExtraBold: {
    fontFamily: 'Archivo',
    fontWeight: '800',
    fontStyle: 'normal'
  } as FontStyleSheet,
  archivoExtraBoldItalic: {
    fontFamily: 'Archivo',
    fontWeight: '800',
    fontStyle: 'italic'
  } as FontStyleSheet,
  archivoBlack: {
    fontFamily: 'Archivo',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,
  archivoBlackItalic: {
    fontFamily: 'Archivo',
    fontWeight: '900',
    fontStyle: 'italic'
  } as FontStyleSheet,

  // Archivo Black
  archivoBlackRegular: {
    fontFamily: 'archivo-black',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,

  // Anton
  antonRegular: {
    fontFamily: 'anton',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,

  // SuisseIntl
  suisseBold: {
    fontFamily: 'SuisseIntl-Bold',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  suisseSemiBold: {
    fontFamily: 'SuisseIntl-SemiBold',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  suisseMedium: {
    fontFamily: 'SuisseIntl-Medium',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  suisseRegular: {
    fontFamily: 'SuisseIntl-Regular',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  suisseBoldItalic: {
    fontFamily: 'SuisseIntl-BoldItalic',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  suisseSemiBoldItalic: {
    fontFamily: 'SuisseIntl-SemiBoldItalic',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  suisseMediumItalic: {
    fontFamily: 'SuisseIntl-MediumItalic',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  suisseRegularItalic: {
    fontFamily: 'SuisseIntl-RegularItalic',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,

  // Montserrat
  montserratThin: {
    fontFamily: 'Montserrat',
    fontWeight: '100',
    fontStyle: 'normal'
  } as FontStyleSheet,
  montserratThinItalic: {
    fontFamily: 'Montserrat',
    fontWeight: '100',
    fontStyle: 'italic'
  } as FontStyleSheet,
  montserratExtraLight: {
    fontFamily: 'Montserrat',
    fontWeight: '200',
    fontStyle: 'normal'
  } as FontStyleSheet,
  montserratExtraLightItalic: {
    fontFamily: 'Montserrat',
    fontWeight: '200',
    fontStyle: 'italic'
  } as FontStyleSheet,
  montserratLight: {
    fontFamily: 'Montserrat',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  montserratLightItalic: {
    fontFamily: 'Montserrat',
    fontWeight: '300',
    fontStyle: 'italic'
  } as FontStyleSheet,
  montserratRegular: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  montserratItalic: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  montserratMedium: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  montserratMediumItalic: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  montserratSemiBold: {
    fontFamily: 'Montserrat',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  montserratSemiBoldItalic: {
    fontFamily: 'Montserrat',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  montserratBold: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  montserratBoldItalic: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  montserratExtraBold: {
    fontFamily: 'Montserrat',
    fontWeight: '800',
    fontStyle: 'normal'
  } as FontStyleSheet,
  montserratExtraBoldItalic: {
    fontFamily: 'Montserrat',
    fontWeight: '800',
    fontStyle: 'italic'
  } as FontStyleSheet,
  montserratBlack: {
    fontFamily: 'Montserrat',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,
  montserratBlackItalic: {
    fontFamily: 'Montserrat',
    fontWeight: '900',
    fontStyle: 'italic'
  } as FontStyleSheet,

  // Realist
  realistThin: {
    fontFamily: 'realistnarrow',
    fontWeight: '100',
    fontStyle: 'normal'
  } as FontStyleSheet,
  realistThinItalic: {
    fontFamily: 'realistnarrow',
    fontWeight: '100',
    fontStyle: 'italic'
  } as FontStyleSheet,
  realistLight: {
    fontFamily: 'realistnarrow',
    fontWeight: '200',
    fontStyle: 'normal'
  } as FontStyleSheet,
  realistLightItalic: {
    fontFamily: 'realistnarrow',
    fontWeight: '200',
    fontStyle: 'italic'
  } as FontStyleSheet,
  realistSemiLight: {
    fontFamily: 'realistnarrow',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  realistSemiLightItalic: {
    fontFamily: 'realistnarrow',
    fontWeight: '300',
    fontStyle: 'italic'
  } as FontStyleSheet,
  realistRegular: {
    fontFamily: 'realistnarrow',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  realistRegularItalic: {
    fontFamily: 'realistnarrow',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  realistMedium: {
    fontFamily: 'realistnarrow',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  realistMediumItalic: {
    fontFamily: 'realistnarrow',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  realistBold: {
    fontFamily: 'realistnarrow',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  realistBoldItalic: {
    fontFamily: 'realistnarrow',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  realistExtraBold: {
    fontFamily: 'realistnarrow',
    fontWeight: '800',
    fontStyle: 'normal'
  } as FontStyleSheet,
  realistExtraBoldItalic: {
    fontFamily: 'realistnarrow',
    fontWeight: '800',
    fontStyle: 'italic'
  } as FontStyleSheet,
  realistBlack: {
    fontFamily: 'realistnarrow',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,
  realistBlackItalic: {
    fontFamily: 'realistnarrow',
    fontWeight: '900',
    fontStyle: 'italic'
  } as FontStyleSheet,

  // Realist Wide
  realistWideThin: {
    fontFamily: 'realistwide',
    fontWeight: '100',
    fontStyle: 'normal'
  } as FontStyleSheet,
  realistWideThinItalic: {
    fontFamily: 'realistwide',
    fontWeight: '100',
    fontStyle: 'italic'
  } as FontStyleSheet,
  realistWideLight: {
    fontFamily: 'realistwide',
    fontWeight: '200',
    fontStyle: 'normal'
  } as FontStyleSheet,
  realistWideLightItalic: {
    fontFamily: 'realistwide',
    fontWeight: '200',
    fontStyle: 'italic'
  } as FontStyleSheet,
  realistWideSemiLight: {
    fontFamily: 'realistwide',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  realistWideSemiLightItalic: {
    fontFamily: 'realistwide',
    fontWeight: '300',
    fontStyle: 'italic'
  } as FontStyleSheet,
  realistWideRegular: {
    fontFamily: 'realistwide',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  realistWideRegularItalic: {
    fontFamily: 'realistwide',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  realistWideMedium: {
    fontFamily: 'realistwide',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  realistWideMediumItalic: {
    fontFamily: 'realistwide',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  realistWideBold: {
    fontFamily: 'realistwide',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  realistWideBoldItalic: {
    fontFamily: 'realistwide',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  realistWideExtraBold: {
    fontFamily: 'realistwide',
    fontWeight: '800',
    fontStyle: 'normal'
  } as FontStyleSheet,
  realistWideExtraBoldItalic: {
    fontFamily: 'realistwide',
    fontWeight: '800',
    fontStyle: 'italic'
  } as FontStyleSheet,
  realistWideBlack: {
    fontFamily: 'realistwide',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,
  realistWideBlackItalic: {
    fontFamily: 'realistwide',
    fontWeight: '900',
    fontStyle: 'italic'
  } as FontStyleSheet,

  // Rubik
  rubikLight: {
    fontFamily: 'rubik',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  rubikLightItalic: {
    fontFamily: 'rubik',
    fontWeight: '300',
    fontStyle: 'italic'
  } as FontStyleSheet,
  rubikRegular: {
    fontFamily: 'rubik',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  rubikItalic: {
    fontFamily: 'rubik',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  rubikMedium: {
    fontFamily: 'rubik',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  rubikMediumItalic: {
    fontFamily: 'rubik',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  rubikMediumBold: {
    fontFamily: 'rubik',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  rubikBold: {
    fontFamily: 'rubik',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  rubikBoldItalic: {
    fontFamily: 'rubik',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  rubikBlack: {
    fontFamily: 'rubik',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,
  rubikBlackItalic: {
    fontFamily: 'rubik',
    fontWeight: '900',
    fontStyle: 'italic'
  } as FontStyleSheet,

  // Source Sans Pro
  sourceSansProRegular: {
    fontFamily: 'source-sans-pro',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  sourceSansProItalic: {
    fontFamily: 'source-sans-pro',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  sourceSansProSemiBold: {
    fontFamily: 'source-sans-pro',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  sourceSansProSemiBoldItalic: {
    fontFamily: 'source-sans-pro',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  sourceSansProBold: {
    fontFamily: 'source-sans-pro',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  sourceSansProBoldItalic: {
    fontFamily: 'source-sans-pro',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  sourceSansProBlack: {
    fontFamily: 'source-sans-pro',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,
  sourceSansProBlackItalic: {
    fontFamily: 'source-sans-pro',
    fontWeight: '900',
    fontStyle: 'italic'
  } as FontStyleSheet,

  // Rift Soft
  riftSoftBold: {
    fontFamily: 'rift-soft',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  riftSoftBoldItalic: {
    fontFamily: 'rift-soft',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,

  // Heebo
  heeboThin: {
    fontFamily: 'heebo',
    fontWeight: '200',
    fontStyle: 'normal'
  } as FontStyleSheet,
  heeboLight: {
    fontFamily: 'heebo',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  heeboRegular: {
    fontFamily: 'heebo',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  heeboMedium: {
    fontFamily: 'heebo',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  heeboBold: {
    fontFamily: 'heebo',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  heeboExtraBold: {
    fontFamily: 'heebo',
    fontWeight: '800',
    fontStyle: 'normal'
  } as FontStyleSheet,
  heeboBlack: {
    fontFamily: 'heebo',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,

  // Lato
  latoHairline: {
    fontFamily: 'lato',
    fontWeight: '100',
    fontStyle: 'normal'
  } as FontStyleSheet,
  latoHairlineItalic: {
    fontFamily: 'lato',
    fontWeight: '100',
    fontStyle: 'italic'
  } as FontStyleSheet,
  latoThin: {
    fontFamily: 'lato',
    fontWeight: '200',
    fontStyle: 'normal'
  } as FontStyleSheet,
  latoThinItalic: {
    fontFamily: 'lato',
    fontWeight: '200',
    fontStyle: 'italic'
  } as FontStyleSheet,
  latoLight: {
    fontFamily: 'lato',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  latoLightItalic: {
    fontFamily: 'lato',
    fontWeight: '300',
    fontStyle: 'italic'
  } as FontStyleSheet,
  latoRegular: {
    fontFamily: 'lato',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  latoItalic: {
    fontFamily: 'lato',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  latoMedium: {
    fontFamily: 'lato',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  latoMediumItalic: {
    fontFamily: 'lato',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  latoSemiBold: {
    fontFamily: 'lato',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  latoSemiBoldItalic: {
    fontFamily: 'lato',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  latoBold: {
    fontFamily: 'lato',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  latoBoldItalic: {
    fontFamily: 'lato',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  latoHeavy: {
    fontFamily: 'lato',
    fontWeight: '800',
    fontStyle: 'normal'
  } as FontStyleSheet,
  latoHeavyItalic: {
    fontFamily: 'lato',
    fontWeight: '800',
    fontStyle: 'italic'
  } as FontStyleSheet,
  latoBlack: {
    fontFamily: 'lato',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,
  latoBlackItalic: {
    fontFamily: 'lato',
    fontWeight: '900',
    fontStyle: 'italic'
  } as FontStyleSheet,

  // Proxima Nova
  proximaNovaThin: {
    fontFamily: 'proxima-nova',
    fontWeight: '100',
    fontStyle: 'normal'
  } as FontStyleSheet,
  proximaNovaThinItalic: {
    fontFamily: 'proxima-nova',
    fontWeight: '100',
    fontStyle: 'italic'
  } as FontStyleSheet,
  proximaNovaLight: {
    fontFamily: 'proxima-nova',
    fontWeight: '300',
    fontStyle: 'normal'
  } as FontStyleSheet,
  proximaNovaLightItalic: {
    fontFamily: 'proxima-nova',
    fontWeight: '300',
    fontStyle: 'italic'
  } as FontStyleSheet,
  proximaNovaRegular: {
    fontFamily: 'proxima-nova',
    fontWeight: '400',
    fontStyle: 'normal'
  } as FontStyleSheet,
  proximaNovaItalic: {
    fontFamily: 'proxima-nova',
    fontWeight: '400',
    fontStyle: 'italic'
  } as FontStyleSheet,
  proximaNovaMedium: {
    fontFamily: 'proxima-nova',
    fontWeight: '500',
    fontStyle: 'normal'
  } as FontStyleSheet,
  proximaNovaMediumItalic: {
    fontFamily: 'proxima-nova',
    fontWeight: '500',
    fontStyle: 'italic'
  } as FontStyleSheet,
  proximaNovaSemiBold: {
    fontFamily: 'proxima-nova',
    fontWeight: '600',
    fontStyle: 'normal'
  } as FontStyleSheet,
  proximaNovaSemiBoldItalic: {
    fontFamily: 'proxima-nova',
    fontWeight: '600',
    fontStyle: 'italic'
  } as FontStyleSheet,
  proximaNovaBold: {
    fontFamily: 'proxima-nova',
    fontWeight: '700',
    fontStyle: 'normal'
  } as FontStyleSheet,
  proximaNovaBoldItalic: {
    fontFamily: 'proxima-nova',
    fontWeight: '700',
    fontStyle: 'italic'
  } as FontStyleSheet,
  proximaNovaExtraBold: {
    fontFamily: 'proxima-nova',
    fontWeight: '800',
    fontStyle: 'normal'
  } as FontStyleSheet,
  proximaNovaExtraBoldItalic: {
    fontFamily: 'proxima-nova',
    fontWeight: '800',
    fontStyle: 'italic'
  } as FontStyleSheet,
  proximaNovaBlack: {
    fontFamily: 'proxima-nova',
    fontWeight: '900',
    fontStyle: 'normal'
  } as FontStyleSheet,
  proximaNovaBlackItalic: {
    fontFamily: 'proxima-nova',
    fontWeight: '900',
    fontStyle: 'italic'
  } as FontStyleSheet,
};
