import { PrintCardMenu } from './print-card-menu';
import { AssetLibraryType } from '../../enum/dto/asset-library-type.enum';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class AgentOrangePrintCardMenu extends PrintCardMenu {

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.metropolisBold,
      FONT.poppinsSemiBold
    ];
  }

  shouldFetchVariantAssets(): boolean {
    return true;
  }

  /**
   * Alternate Brand asset should be displayed if available (those work better with a dark background) - no product
   * fallback.
   */
  variantAssetTypeSortOrder(): string[] {
    return [
      AssetLibraryType.AlternateBrand,
      AssetLibraryType.PrimaryBrand,
      AssetLibraryType.Brand,
    ];
  }

}
