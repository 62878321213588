import { Type } from '@angular/core';
import { ProductMenu } from '../product-menu';
import { MenuHeaderTitleImageWithBackgroundAssetComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image-with-background-asset/menu-header-title-image-with-background-asset.component';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { Section } from '../section/section';
import { SectionUtils } from '../../../utils/section-utils';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class NightMenu extends ProductMenu {

  getThemeClass(): string {
    return 'night-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageWithBackgroundAssetComponent;
  }

  getShowHeader(): boolean {
    return true;
  }

  getShowFooter(): boolean {
    return false;
  }

  getTitleSectionTopMargin(): string {
    return '0.5rem';
  }

  getTitleSectionBottomMargin(): string {
    return '0';
  }

  getSectionTitleUnderlineColor(section: Section): string {
    if (SectionUtils.isSectionWithProducts(section)) {
      return this.getSectionHeaderTextColor(section);
    }
    return super.getSectionTitleUnderlineColor(section);
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.rubikBold,
      FONT.rubikMedium,
      FONT.rubikRegular,
    ];
  }

}
