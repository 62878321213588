import { Type } from '@angular/core';
import { CenteredLogoMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/centered-logo-menu-footer/centered-logo-menu-footer.component';
import type { SectionWithProducts } from '../section/section-with-products';
import { Product } from '../../product/dto/product';
import { Variant } from '../../product/dto/variant';
import { ColWidth } from '../../shared/col-width';
import { SectionColumnProductInfoType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { PrintMenu } from '../print-menu';
import { PrintFooterLayoutType } from '../../enum/shared/print-footer-layout-type.enum';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { MenuHeaderTitleComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title.component';
import { ColumnUtils } from '../../../utils/column-utils';
import { ColumnGrouping } from '../section/column-grouping';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';
import { FontStyleSheet, FONT } from '../font-style-sheets';

export class BlendedMenu extends PrintMenu {

  getPageTopMargin(): string {
    return '0.25in';
  }

  getPageBottomMargin(): string {
    return '0.25in';
  }

  getPageLeftMargin(): string {
    return '0.25in';
  }

  getPageRightMargin(): string {
    return '0.25in';
  }

  getThemeClass(): string {
    return 'blended-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleComponent;
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.FirstPage;
  }

  getFooterType(): Type<CenteredLogoMenuFooterComponent> {
    return CenteredLogoMenuFooterComponent;
  }

  getPrintFooterLayoutType(): PrintFooterLayoutType {
    return this.menuOptions?.printFooterLayout || PrintFooterLayoutType.AllPagesFixed;
  }

  brandInlineWithProductName(): boolean {
    return true;
  }

  getDisplayPadding(): string {
    return '0rem';
  }

  getProductTitle(
    section: SectionWithProducts,
    product: Product,
    variants: Variant[],
    priceStream: LocationPriceStream
  ): string {
    const productTitle = super.getProductTitle(section, product, variants, priceStream);
    return product.getBrand(variants.map(v => v.id)) ? productTitle + ' | ' : productTitle;
  }

  getShowProductSubtitle(): boolean {
    return false;
  }

  getShowStrainTypes(): boolean {
    return true;
  }

  getShowStrainTypesOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  // this does not affect the badge column, only inline badges within title or subtitle
  getShowBadgesInline(): boolean {
    return true;
  }

  getShowBadgesInlineOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.playfairDisplayBold,
      FONT.ralewayBold,
      FONT.ralewayMedium,
      FONT.ralewayItalic,
      FONT.acuminProExtraCondensedRegular
    ];
  }

  getTitleSectionTopMargin(): string {
    return '0rem';
  }

  getTitleSectionBottomMargin(): string {
    return '0rem';
  }

  getSectionWrapperClass(...args: any[]): string {
    return '';
  }

  getProductWrapperClass(odd: boolean) {
    return 'product-row-overlay';
  }

  getLowAmountStyling(section: SectionWithProducts, rowVM: SectionRowViewModel, colVM: SectionColumnViewModel): any {
    return {
      ...super.getLowAmountStyling(section, rowVM, colVM),
      'border-color': `rgba(0, 0, 0, 1)`
    };
  }

  override getThemeSpecifiedColumnViewModels(
    sectionRowViewModels: SectionRowViewModel[],
    rowViewModel: SectionRowViewModel,
    widths: ColWidth[] = this.getColWidths(rowViewModel)
  ): SectionColumnViewModel[] {
    const vms = super.getThemeSpecifiedColumnViewModels(sectionRowViewModels, rowViewModel, widths);
    return vms?.filter(vm => vm.columnType !== SectionColumnProductInfoType.TinySpacer);
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

}
