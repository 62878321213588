import { CardData } from '../card-data';
import { ProductType } from '../../enum/dto/product-type.enum';
import { SectionColumnConfigDataValue } from '../../menu/section/section-column-config';

export abstract class FireAndFlowerCardData extends CardData {

  public abstract readonly showRegularPriceAtBottom: boolean;

  copyFrom(cardData: FireAndFlowerCardData) {
    this.menu = cardData?.menu;
    this.section = cardData?.section;
    this.product = cardData?.product;
    this.rowVariants = cardData?.rowVariants;
    this.configTheme = cardData?.configTheme;
    this.hideLabel = cardData?.hideLabel;
    this.variantLineItemMode = cardData?.variantLineItemMode;
    this.companyConfig = cardData?.companyConfig;
    this.locationConfig = cardData?.locationConfig;
    this.variantBadgeMap = cardData?.variantBadgeMap;
  }

  override getCannabinoid(
    cannabinoid: string,
    displayFormat: SectionColumnConfigDataValue = SectionColumnConfigDataValue.NumericValue
  ): string {
    const c = super.getCannabinoid(cannabinoid, displayFormat);
    const validProductType = this.rowProductType() === ProductType.Edible;
    const empty = c?.includes('<') || c === 'N/A' || c === '-' || c === '--';
    return (!validProductType || empty) ? null : c;
  }

}
