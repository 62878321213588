import { ProductMenu } from '../product-menu';
import { Type } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { MenuHeaderCenteredLogoRightDescAndImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-logo-desc-image/menu-header-centered-logo-right-desc-and-image/menu-header-centered-logo-right-desc-and-image.component';
import { MenuHeaderLeftLogoRightDescAndImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-logo-desc-image/menu-header-left-logo-right-desc-and-image/menu-header-left-logo-right-desc-and-image.component';
import { BandedRowMode } from '../../enum/shared/banded-row-mode.enum';
import { BandedRowColorContrast } from '../../enum/shared/banded-row-color-contrast.enum';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class TrueNorthMenu extends ProductMenu {

  getThemeClass(): string {
    return 'true-north-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    if (this.isLandscape()) return MenuHeaderCenteredLogoRightDescAndImageComponent;
    else return MenuHeaderLeftLogoRightDescAndImageComponent;
  }

  getShowHeader(): boolean {
    return true;
  }

  getShowFooter(): boolean {
    return false;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getProductWrapperClass(odd: boolean) {
    return odd ? 'product-row-overlay' : '';
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.Badge, 0],
      [SectionColumnProductInfoType.ProductTitle, 1],
      [SectionColumnProductInfoType.StrainType, 2],
      [SectionColumnProductInfoType.Brand, 3],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(4),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(5),
      [SectionColumnProductInfoType.QuantityAndSize, 6],
      [SectionColumnProductInfoType.Quantity, 7],
      [SectionColumnProductInfoType.Size, 8],
      [SectionColumnProductInfoType.VariantPrice, 9],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 10],
    ]);
  }

  bandedRowMode(): BandedRowMode {
    return BandedRowMode.Odd;
  }

  bandedRowColor(rowViewModel: SectionRowViewModel, odd: boolean): string {
    if (this.currentRowIsBanded(odd)) {
      const opacity = '0.65';
      return `rgba(255, 255, 255, ${opacity})`;
    } else {
      return null;
    }
  }

  bandedRowColorContrast(): BandedRowColorContrast {
    return BandedRowColorContrast.Lighten;
  }

  getShowProductSubtitle(): boolean {
    return false;
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.gilroyExtraBold,
      FONT.gilroySemiBold,
      FONT.montserratBold,
      FONT.montserratMedium
    ];
  }

  getTitleSectionTopMargin(): string {
    return '0';
  }

  getTitleSectionBottomMargin(): string {
    return '0';
  }

}
