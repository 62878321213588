import { FONT, FontStyleSheet } from '../font-style-sheets';
import { PrintCardMenu } from './print-card-menu';

export class GanjikaBeveragePrintCardMenu extends PrintCardMenu {

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.neueHaasGroteskDisplayRegular,
      FONT.neueHaasGroteskDisplayMedium,
      FONT.neueHaasGroteskDisplayRegularItalic
    ];
  }

  shouldStandardizeDominantStrainType(): boolean {
    return false;
  }

}
