import { Type } from '@angular/core';
import { ProductMenu } from '../product-menu';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { Section } from '../section/section';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { ColumnUtils } from '../../../utils/column-utils';
import { ColumnGrouping } from '../section/column-grouping';
import { StrainType } from '../../enum/dto/strain-classification.enum';
import { SectionLayoutType } from '../../enum/dto/section-layout-type.enum';
import { StringUtils } from '../../../utils/string-utils';
import { SortVariantUtils } from '../../../utils/sort-variant-utils';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class LobsterButterMenu extends ProductMenu {

  getThemeClass(): string {
    return 'lobster-butter-theme';
  }

  getHeaderType(): Type<MenuHeaderTitleImageComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getShowFooter(): boolean {
    return false;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.StrainType, 1],
      [SectionColumnProductInfoType.ProductTitle, 2],
      [SectionColumnProductInfoType.Badge, 3],
      [SectionColumnProductInfoType.Brand, 4],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(5),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(6),
      [SectionColumnProductInfoType.QuantityAndSize, 7],
      [SectionColumnProductInfoType.Quantity, 8],
      [SectionColumnProductInfoType.Size, 9],
      [SectionColumnProductInfoType.VariantPrice, 10],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 11],
    ]);
  }

  getShouldInflateColumnHeaderForRow(
    s: Section,
    col: SectionColumnViewModel,
    rows: SectionRowViewModel[]
  ): boolean {
    const classification = col?.columnType === SectionColumnProductInfoType.StrainType;
    return !classification;
  }

  getUniqueClassificationList(): StrainType[] {
    return super.getUniqueClassificationList()?.sort(SortVariantUtils.genericStrainTypeSort);
  }

  getPriceColumnTitle(col: SectionColumnViewModel): string {
    const title = super.getPriceColumnTitle(col);
    return (col.sectionLayoutType !== SectionLayoutType.List) ? StringUtils.removeWhiteSpace(title) : title;
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.robotoMedium,
      FONT.robotoRegular,
      FONT.robotoBlack,
      FONT.merriweatherBold,
      FONT.merriweatherUltraBold
    ];
  }

}
