import { FONT, FontStyleSheet } from '../font-style-sheets';
import { PrintCardMenu } from './print-card-menu';

export class MissJonesPrintCardMenu extends PrintCardMenu {

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.oswaldSemiBold,
      FONT.futuraPtBook,
      FONT.ramaGothicMSemiBold
    ];
  }

  shouldStandardizeDominantStrainType(): boolean {
    return false;
  }

}
