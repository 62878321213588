import { ProductMenu } from '../product-menu';
import { MenuType } from '../../enum/dto/menu-type.enum';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { SectionColumnProductInfoType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { ColWidth } from '../../shared/col-width';
import type { SectionWithProducts } from '../section/section-with-products';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class SmallJaneMenu extends ProductMenu {

  getThemeClass(): string {
    return 'small-jane-theme' + (this.type === MenuType.WebMenu ? ' web-menu' : '');
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getDefaultBackgroundImageUrl(): string {
    return 'assets/images/PaperTexture.png';
  }

  getPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel,
  ): string {
    if (!this.getDropDecimal()) {
      const price = this.getVariantPricing(priceStream, locationId, s, rowVM, colVm, false, true);
      const dec = price.split('.')?.[1];
      // decimals and formatting are included. ie: $1000.00
      if (price === '-' || price.length > 7 || dec === '') {
        return '';
      } else {
        return dec;
      }
    } else {
      return '';
    }
  }

  override getThemeSpecifiedColumnViewModels(
    sectionRowViewModels: SectionRowViewModel[],
    rowViewModel: SectionRowViewModel,
    widths: ColWidth[] = this.getColWidths(rowViewModel)
  ): SectionColumnViewModel[] {
    let vms = this.getThemeStandardizedColumnViewModels(sectionRowViewModels, rowViewModel, widths);
    // Remove big spaces
    vms = vms.filter(vm => vm.columnType !== SectionColumnProductInfoType.Spacer);
    return vms;
  }

  getColumnGroupingSpacerWidthPercentage(): number {
    return 0;
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.openSansRegular,
      FONT.poppinsExtraLight,
      FONT.poppinsRegular,
      FONT.poppinsMedium,
      FONT.poppinsLight,
      FONT.poppinsThin,
      FONT.poppinsBold
    ];
  }

  brandInlineWithProductName(): boolean {
    return true;
  }

  getShowProductSubtitle(): boolean {
    return false;
  }

}
