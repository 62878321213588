import { FONT, FontStyleSheet } from '../font-style-sheets';
import { PrintCardMenu } from './print-card-menu';

export class OGKushPrintCardMenu extends PrintCardMenu {

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.poppinsBold,
      FONT.poppinsRegular,
      FONT.poppinsSemiBold,
      FONT.poppinsSemiBoldItalic
    ];
  }

  shouldStandardizeDominantStrainType() {
    return false;
  }

}
