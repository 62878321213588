import { PrintMenu } from '../print-menu';
import { Type } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { ProductMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/product-menu-footer.component';
import { EmptyMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/empty-menu-footer/empty-menu-footer.component';
import { PrintFooterLayoutType } from '../../enum/shared/print-footer-layout-type.enum';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class OGKushPrintMenu extends PrintMenu {

  getThemeClass(): string {
    return 'og-kush-print-theme';
  }

  getPageBottomMargin(): string {
    return '0.5in';
  }

  getPageTopMargin(): string {
    return '0.5in';
  }

  getPageLeftMargin(): string {
    return '0.5in';
  }

  getPageRightMargin(): string {
    return '0.5in';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.FirstPage;
  }

  getPrintFooterLayoutType(): PrintFooterLayoutType {
    return this.menuOptions?.printFooterLayout || PrintFooterLayoutType.AllPagesHugging;
  }

  getPriceColumnTitle(col: SectionColumnViewModel): string {
    return super.getPriceColumnTitle(col).replace(/\b(g|mg|ml)\b/, (x) => x.toUpperCase());
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.poppinsBold,
      FONT.poppinsRegular,
      FONT.poppinsSemiBold,
      FONT.poppinsMedium,
      FONT.rubikMedium
    ];
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getShowBadgesInline(): boolean {
    return true;
  }

  getShowBadgesInlineOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getShowFooter(): boolean {
    return super.getShowFooter();
  }

  getFooterType(): Type<ProductMenuFooterComponent> {
    return EmptyMenuFooterComponent;
  }

}
