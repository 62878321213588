import { Type } from '@angular/core';
import { Section } from '../section/section';
import { ProductMenu } from '../product-menu';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { ProductMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/product-menu-footer.component';
import { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { EmptyMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/empty-menu-footer/empty-menu-footer.component';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { BandedRowMode } from '../../enum/shared/banded-row-mode.enum';
import type { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class DougMenu extends ProductMenu {

  getThemeClass(): string {
    return 'doug-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getFooterType(): Type<ProductMenuFooterComponent> {
    return EmptyMenuFooterComponent;
  }

  getShowFooter(): boolean {
    return true;
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getShouldHideColumnContent(s: Section, col: SectionColumnViewModel, row: SectionRowViewModel): boolean {
    return false;
  }

  getProductWrapperClass(odd: boolean): string {
    if (odd) {
      return 'product-odd';
    } else {
      return 'product-even';
    }
  }

  bandedRowMode(): BandedRowMode {
    return BandedRowMode.Even;
  }

  bandedRowsDefaultEnabled(): boolean {
    return true;
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.suisseBold,
      FONT.suisseSemiBold,
      FONT.suisseMedium,
      FONT.poppinsExtraBold
    ];
  }

  getTitleSectionBottomMargin(): string {
    return '0';
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

}
