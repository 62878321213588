import { PrintCardMenu } from './print-card-menu';
import { AssetLibraryType } from '../../enum/dto/asset-library-type.enum';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class PlantlifePrintCardMenu extends PrintCardMenu {

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.bebasNeueProRegular,
      FONT.bebasNeueProBold,
      FONT.bebasNeueProExpandedBold,
      FONT.bebasNeueProExpandedBoldItalic
    ];
  }

  override shouldFetchVariantAssets(): boolean {
    return true;
  }

  /**
   * Brand asset should be displayed if available - no product fallback.
   */
  override variantAssetTypeSortOrder(): string[] {
    return [
      AssetLibraryType.PrimaryBrand,
      AssetLibraryType.AlternateBrand,
      AssetLibraryType.Brand,
    ];
  }

}
