export enum SectionSortProductInfo {
  BrandAsc = 'BRAND_ASC',
  BrandDesc = 'BRAND_DESC',
  CBDAsc = 'CBD_ASC',
  CBDDesc = 'CBD_DESC',
  ClassificationAsc = 'CLASSIFICATION_ASC',
  ClassificationDesc = 'CLASSIFICATION_DESC',
  ManufacturerAsc = 'MANUFACTURER_ASC',
  ManufacturerDesc = 'MANUFACTURER_DESC',
  PackagedQuantityAsc = 'PACKAGED_QUANTITY_ASC',
  PackagedQuantityDesc = 'PACKAGED_QUANTITY_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  ProductTypeAsc = 'PRODUCT_TYPE_ASC',
  ProductTypeDesc = 'PRODUCT_TYPE_DESC',
  SecondaryPriceAsc = 'SECONDARY_PRICE_ASC',
  SecondaryPriceDesc = 'SECONDARY_PRICE_DESC',
  StockAsc = 'STOCK_ASC',
  StockDesc = 'STOCK_DESC',
  TACAsc = 'TAC_ASC',
  TACDesc = 'TAC_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  THCAsc = 'THC_ASC',
  THCDesc = 'THC_DESC',
  TopTerpeneAsc = 'TOP_TERPENE_ASC',
  TopTerpeneDesc = 'TOP_TERPENE_DESC',
  TotalTerpeneAsc = 'TOTAL_TERPENE_ASC',
  TotalTerpeneDesc = 'TOTAL_TERPENE_DESC',
  UnitSizeAsc = 'UNIT_SIZE_ASC',
  UnitSizeDesc = 'UNIT_SIZE_DESC',
  VariantTypeAsc = 'VARIANT_TYPE_ASC',
  VariantTypeDesc = 'VARIANT_TYPE_DESC',
}

export enum SectionSortSecondaryCannabinoids {
  CBDAAsc = 'CBDA_ASC',
  CBDADesc = 'CBDA_DESC',
  CBGAsc = 'CBG_ASC',
  CBGDesc = 'CBG_DESC',
  CBGAAsc = 'CBGA_ASC',
  CBGADesc = 'CBGA_DESC',
  CBLAsc = 'CBL_ASC',
  CBLDesc = 'CBL_DESC',
  CBLAAsc = 'CBLA_ASC',
  CBLADesc = 'CBLA_DESC',
  CBNAsc = 'CBN_ASC',
  CBNDesc = 'CBN_DESC',
  CBNAAsc = 'CBNA_ASC',
  CBNADesc = 'CBNA_DESC',
  CBTAsc = 'CBT_ASC',
  CBTDesc = 'CBT_DESC',
  CBCAsc = 'CBC_ASC',
  CBCDesc = 'CBC_DESC',
  CBCAAsc = 'CBCA_ASC',
  CBCADesc = 'CBCA_DESC',
  CBCVAsc = 'CBCV_ASC',
  CBCVDesc = 'CBCV_DESC',
  CBDVAsc = 'CBDV_ASC',
  CBDVDesc = 'CBDV_DESC',
  THC8Asc = 'THC8_ASC',
  THC8Desc = 'THC8_DESC',
  THC9Asc = 'THC9_ASC',
  THC9Desc = 'THC9_DESC',
  THCAAsc = 'THCA_ASC',
  THCADesc = 'THCA_DESC',
  THCVAsc = 'THCV_ASC',
  THCVDesc = 'THCV_DESC',
}

export enum SectionSortTerpenes {
  AlphaBisabololAsc = 'ALPHA_BISABOLOL_ASC',
  AlphaBisabololDesc = 'ALPHA_BISABOLOL_DESC',
  AlphaCaryophylleneAsc = 'ALPHA_CARYOPHYLLENE_ASC',
  AlphaCaryophylleneDesc = 'ALPHA_CARYOPHYLLENE_DESC',
  AlphaCedreneAsc = 'ALPHA_CEDRENE_ASC',
  AlphaCedreneDesc = 'ALPHA_CEDRENE_DESC',
  AlphaHumuleneAsc = 'ALPHA_HUMULENE_ASC',
  AlphaHumuleneDesc = 'ALPHA_HUMULENE_DESC',
  AlphaMyrceneAsc = 'ALPHA_MYRCENE_ASC',
  AlphaMyrceneDesc = 'ALPHA_MYRCENE_DESC',
  AlphaPineneAsc = 'ALPHA_PINENE_ASC',
  AlphaPineneDesc = 'ALPHA_PINENE_DESC',
  AlphaPhellandreneAsc = 'ALPHA_PHELLANDRENE_ASC',
  AlphaPhellandreneDesc = 'ALPHA_PHELLANDRENE_DESC',
  AlphaSantaleneAsc = 'ALPHA_SANTALENE_ASC',
  AlphaSantaleneDesc = 'ALPHA_SANTALENE_DESC',
  AlphaTerpineneAsc = 'ALPHA_TERPINENE_ASC',
  AlphaTerpineneDesc = 'ALPHA_TERPINENE_DESC',
  AlphaTerpineolAsc = 'ALPHA_TERPINEOL_ASC',
  AlphaTerpineolDesc = 'ALPHA_TERPINEOL_DESC',
  BetaCaryophylleneAsc = 'BETA_CARYOPHYLLENE_ASC',
  BetaCaryophylleneDesc = 'BETA_CARYOPHYLLENE_DESC',
  BetaEudesmolAsc = 'BETA_EUDESMOL_ASC',
  BetaEudesmolDesc = 'BETA_EUDESMOL_DESC',
  BetaMyrceneAsc = 'BETA_MYRCENE_ASC',
  BetaMyrceneDesc = 'BETA_MYRCENE_DESC',
  BetaOcimeneAsc = 'BETA_OCIMENE_ASC',
  BetaOcimeneDesc = 'BETA_OCIMENE_DESC',
  BetaPineneAsc = 'BETA_PINENE_ASC',
  BetaPineneDesc = 'BETA_PINENE_DESC',
  BergamoteneAsc = 'BERGAMOTENE_ASC',
  BergamoteneDesc = 'BERGAMOTENE_DESC',
  BisabololAsc = 'BISABOLOL_ASC',
  BisabololDesc = 'BISABOLOL_DESC',
  BorneolAsc = 'BORNEOL_ASC',
  BorneolDesc = 'BORNEOL_DESC',
  CadineneAsc = 'CADINENE_ASC',
  CadineneDesc = 'CADINENE_DESC',
  CampeneAsc = 'CAMPENE_ASC',
  CampeneDesc = 'CAMPENE_DESC',
  CamphorAsc = 'CAMPHOR_ASC',
  CamphorDesc = 'CAMPHOR_DESC',
  CareneAsc = 'CARENE_ASC',
  CareneDesc = 'CARENE_DESC',
  CaryophylleneOxideAsc = 'CARYOPHYLLENE_OXIDE_ASC',
  CaryophylleneOxideDesc = 'CARYOPHYLLENE_OXIDE_DESC',
  CarvacrolAsc = 'CARVACROL_ASC',
  CarvacrolDesc = 'CARVACROL_DESC',
  CarvoneAsc = 'CARVONE_ASC',
  CarvoneDesc = 'CARVONE_DESC',
  CaryophylleneAsc = 'CARYOPHYLLENE_ASC',
  CaryophylleneDesc = 'CARYOPHYLLENE_DESC',
  CedreneAsc = 'CEDRENE_ASC',
  CedreneDesc = 'CEDRENE_DESC',
  CedrolAsc = 'CEDROL_ASC',
  CedrolDesc = 'CEDROL_DESC',
  CisNerolidolAsc = 'CIS_NEROLIDOL_ASC',
  CisNerolidolDesc = 'CIS_NEROLIDOL_DESC',
  CisOcimeneAsc = 'CIS_OCIMENE_ASC',
  CisOcimeneDesc = 'CIS_OCIMENE_DESC',
  CitralAsc = 'CITRAL_ASC',
  CitralDesc = 'CITRAL_DESC',
  CitronellolAsc = 'CITRONELLOL_ASC',
  CitronellolDesc = 'CITRONELLOL_DESC',
  CymeneAsc = 'CYMENE_ASC',
  CymeneDesc = 'CYMENE_DESC',
  CymeneneAsc = 'CYMENENE_ASC',
  CymeneneDesc = 'CYMENENE_DESC',
  Delta3CareneAsc = 'DELTA3_CARENE_ASC',
  Delta3CareneDesc = 'DELTA3_CARENE_DESC',
  DeltaLimoneneAsc = 'DELTA_LIMONENE_ASC',
  DeltaLimoneneDesc = 'DELTA_LIMONENE_DESC',
  EndoFenchylAsc = 'ENDO_FENCHYL_ASC',
  EndoFenchylDesc = 'ENDO_FENCHYL_DESC',
  EucalyptolAsc = 'EUCALYPTOL_ASC',
  EucalyptolDesc = 'EUCALYPTOL_DESC',
  EudesmolsAsc = 'EUDESMOLS_ASC',
  EudesmolsDesc = 'EUDESMOLS_DESC',
  EugenolAsc = 'EUGENOL_ASC',
  EugenolDesc = 'EUGENOL_DESC',
  FarneseneAsc = 'FARNESENE_ASC',
  FarneseneDesc = 'FARNESENE_DESC',
  FarneolAsc = 'FARNEOL_ASC',
  FarneolDesc = 'FARNEOL_DESC',
  FencholAsc = 'FENCHOL_ASC',
  FencholDesc = 'FENCHOL_DESC',
  FenchoneAsc = 'FENCHONE_ASC',
  FenchoneDesc = 'FENCHONE_DESC',
  FenchylAsc = 'FENCHYL_ASC',
  FenchylDesc = 'FENCHYL_DESC',
  GeraniolAsc = 'GERANIOL_ASC',
  GeraniolDesc = 'GERANIOL_DESC',
  GeranylAcetateAsc = 'GERANYL_ACETATE_ASC',
  GeranylAcetateDesc = 'GERANYL_ACETATE_DESC',
  GermacreneAsc = 'GERMACRENE_ASC',
  GermacreneDesc = 'GERMACRENE_DESC',
  GuaiolAsc = 'GUAIOL_ASC',
  GuaiolDesc = 'GUAIOL_DESC',
  HumuleneAsc = 'HUMULENE_ASC',
  HumuleneDesc = 'HUMULENE_DESC',
  IsopoulegolAsc = 'ISOPOULEGOL_ASC',
  IsopoulegolDesc = 'ISOPOULEGOL_DESC',
  LinaloolAsc = 'LINALOOL_ASC',
  LinaloolDesc = 'LINALOOL_DESC',
  LimoneneAsc = 'LIMONENE_ASC',
  LimoneneDesc = 'LIMONENE_DESC',
  MyrceneAsc = 'MYRCENE_ASC',
  MyrceneDesc = 'MYRCENE_DESC',
  NerolAsc = 'NEROL_ASC',
  NerolDesc = 'NEROL_DESC',
  NerolidolAsc = 'NEROLIDOL_ASC',
  NerolidolDesc = 'NEROLIDOL_DESC',
  OcimeneAsc = 'OCIMENE_ASC',
  OcimeneDesc = 'OCIMENE_DESC',
  ParaCymeneneAsc = 'PARA_CYMENENE_ASC',
  ParaCymeneneDesc = 'PARA_CYMENENE_DESC',
  PhellandreneAsc = 'PHELLANDRENE_ASC',
  PhellandreneDesc = 'PHELLANDRENE_DESC',
  PhytolAsc = 'PHYTOL_ASC',
  PhytolDesc = 'PHYTOL_DESC',
  PineneAsc = 'PINENE_ASC',
  PineneDesc = 'PINENE_DESC',
  PulegoneAsc = 'PULEGONE_ASC',
  PulegoneDesc = 'PULEGONE_DESC',
  SabineneAsc = 'SABINENE_ASC',
  SabineneDesc = 'SABINENE_DESC',
  SantaleneAsc = 'SANTALENE_ASC',
  SantaleneDesc = 'SANTALENE_DESC',
  ThreeCareneAsc = 'THREE_CARENE_ASC',
  ThreeCareneDesc = 'THREE_CARENE_DESC',
  TerpineneAsc = 'TERPINENE_ASC',
  TerpineneDesc = 'TERPINENE_DESC',
  TerpineolAsc = 'TERPINEOL_ASC',
  TerpineolDesc = 'TERPINEOL_DESC',
  TerpinoleneAsc = 'TERPINOLENE_ASC',
  TerpinoleneDesc = 'TERPINOLENE_DESC',
  TransNerolidolAsc = 'TRANS_NEROLIDOL_ASC',
  TransNerolidolDesc = 'TRANS_NEROLIDOL_DESC',
  TransCaryophylleneAsc = 'TRANS_CARYOPHYLLENE_ASC',
  TransCaryophylleneDesc = 'TRANS_CARYOPHYLLENE_DESC',
  ValenceneAsc = 'VALENCENE_ASC',
  ValenceneDesc = 'VALENCENE_DESC',
}

export type SectionSortOption = SectionSortProductInfo | SectionSortSecondaryCannabinoids | SectionSortTerpenes;
