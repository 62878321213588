import { Type } from '@angular/core';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { Section } from '../section/section';
import { Product } from '../../product/dto/product';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { ProductMenu } from '../product-menu';
import { SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { ColWidth } from '../../shared/col-width';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class LaCanapaMenu extends ProductMenu {

  getThemeClass(): string {
    return 'la-canapa-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getShowHeader(): boolean {
    return true;
  }

  getShowFooter(): boolean {
    return false;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getProductSubtitle(
    section: Section,
    product: Product,
    rowVm: SectionRowViewModel
  ): string {
    const variant = rowVm.rowVariants?.firstOrNull();
    return variant ? variant.description : '';
  }

  getSectionHeaderUnderlined(): boolean {
    return true;
  }

  getSectionTitleUnderlineColor(section: Section): string {
    return '#731818';
  }

  getTitleSectionTopMargin(): string {
    return '0';
  }

  getTitleSectionBottomMargin(): string {
    return '0.5rem';
  }

  getShowStrainTypes(): boolean {
    return super.getShowStrainTypes();
  }

  override getThemeSpecifiedColumnViewModels(
    sectionRowViewModels: SectionRowViewModel[],
    sectionRowViewModel: SectionRowViewModel,
    widths: ColWidth[] = this.getColWidths(sectionRowViewModel)
  ): SectionColumnViewModel[] {
    const vms = super.getThemeSpecifiedColumnViewModels(sectionRowViewModels, sectionRowViewModel, widths);
    if (!sectionRowViewModel.hideLabel) {
      const layoutType = sectionRowViewModel?.getLayoutType();
      const labelCol = new SectionColumnViewModel(layoutType, null);
      labelCol.columnType = SectionColumnProductInfoType.Label;
      labelCol.columnTitle = '';
      labelCol.widthPercentage = '10';
      vms.splice(0, 0, labelCol);
    }
    return vms;
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.Label, 0],
      [SectionColumnProductInfoType.StrainType, 1],
      [SectionColumnProductInfoType.ProductTitle, 2],
      [SectionColumnProductInfoType.Brand, 3],
      [SectionColumnProductInfoType.Badge, 4],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(5),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(6),
      [SectionColumnProductInfoType.QuantityAndSize, 7],
      [SectionColumnProductInfoType.Quantity, 8],
      [SectionColumnProductInfoType.Size, 9],
      [SectionColumnProductInfoType.VariantPrice, 10],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 11],
    ]);
  }

  getShouldInflateColumnHeaderForRow(
    s: Section,
    col: SectionColumnViewModel,
    rows: SectionRowViewModel[]
  ): boolean {
    const label = col?.columnType === SectionColumnProductInfoType.Label;
    const classification = col?.columnType === SectionColumnProductInfoType.StrainType;
    return !label && !classification;
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getShowInlineLabels(): boolean {
    return false;
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.openSansBold,
      FONT.openSansRegular,
      FONT.openSansSemiBold,
      FONT.openSansBoldExtra
    ];
  }

}
