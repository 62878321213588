import { Type } from '@angular/core';
import { ProductMenu } from '../product-menu';
import { MenuHeaderTitleComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title.component';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class FoggyMenu extends ProductMenu {

  getThemeClass(): string {
    return 'foggy-theme';
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleComponent;
  }

  getShowHeader(): boolean {
    return true;
  }

  getShowStrainTypes(): boolean {
    return true;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getShowFooter(): boolean {
    return true;
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.bigNoodle,
      FONT.heeboRegular,
      FONT.heeboLight,
      FONT.heeboBold
    ];
  }

}
