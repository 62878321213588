import { LocationPriceStream } from '../../enum/shared/location-price-stream';
import type { SectionWithProducts } from '../section/section-with-products';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { SectionColumnProductInfoType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { PriceUtils } from '../../../utils/price-utils';
import { ProductMenu } from '../product-menu';
import { Fraction } from '../../enum/shared/fraction.enum';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class IngramAndSonsMenu extends ProductMenu {

  public static readonly supportedDecimalsThatCanBeFractions: Fraction[] = [
    Fraction.Eighth,
    Fraction.Quarter,
    Fraction.ThreeEighths,
    Fraction.Half,
    Fraction.FiveEighths,
    Fraction.ThreeQuarters,
    Fraction.SevenEighths
  ];

  getThemeClass(): string {
    return 'ingram-and-sons-theme';
  }

  getTitleSectionTopMargin(): string {
    return '1rem';
  }

  getTitleSectionBottomMargin(): string {
    return '0.2rem';
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return false;
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel,
  ): string {
    const decimal = super.getPriceDecimal(priceStream, locationId, s, rowVM, colVm);
    const supportedFractions = IngramAndSonsMenu.supportedDecimalsThatCanBeFractions;
    return PriceUtils.getCertainDecimalsAsAsciiFractions(decimal, supportedFractions, true);
  }

  getSecondaryPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel
  ): string {
    const decimal = super.getSecondaryPriceDecimal(priceStream, locationId, s, rowVM, colVm);
    const supportedFractions = IngramAndSonsMenu.supportedDecimalsThatCanBeFractions;
    return PriceUtils.getCertainDecimalsAsAsciiFractions(decimal, supportedFractions, true);
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    const outBadges = (sectionColumnViewModel: SectionColumnViewModel) => {
      return sectionColumnViewModel.columnType !== SectionColumnProductInfoType.Badge;
    };
    if (sectionColumnViewModels?.filter(outBadges)?.length >= 5) {
      return ColumnUtils.oneBigGrouping();
    }
    return ColumnUtils.standardizedGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    const outBadges = (sectionColumnViewModel: SectionColumnViewModel) => {
      return sectionColumnViewModel.columnType !== SectionColumnProductInfoType.Badge;
    };
    if (sectionColumnViewModels?.filter(outBadges)?.length >= 7) {
      return ColumnUtils.oneBigGrouping();
    }
    return ColumnUtils.standardizedGrouping();
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.antonRegular,
      FONT.montserratMedium,
      FONT.montserratBold,
      FONT.spectralExtraBold,
      FONT.spectralBold
    ];
  }

}
