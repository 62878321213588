import { Deserializable } from '../../protocols/deserializable';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { CannabinoidDisplayType } from '../../enum/shared/cannabinoid-display-type.enum';
import { SaleLabelFormat } from '../../enum/shared/sale-label-format.enum';
import { LabelStyle } from '../../enum/shared/label-style.enum';
import { TerpeneDisplayType } from '../../enum/shared/terpene-display-type';
import { Cannabinoid } from '../../enum/shared/cannabinoid';
import { StringUtils } from '../../../utils/string-utils';

export const DEFAULT_LABEL_ORDER = 12;

export class CompanyConfiguration implements Deserializable, Cachable, UniquelyIdentifiable {

  public companyId: number;
  public lowStockThreshold: number;
  public restockThresholdHours: number;
  public saleLabelFormat: SaleLabelFormat;
  public labelStyle: LabelStyle;
  public enabledCannabinoids: Cannabinoid[];
  public enabledTerpenes: string[]; // enabled terpenes is pascal cased upon deserialization

  /**
   * Below information only pertains to the [Display], and not [Dashboard] or [Admin]
   *
   * This signal is consolidated into every variant.useCannabinoidRange before the data leaves the backend.
   * So variant.useCannabinoidRange within the [Display] is actually:
   * variant.useCannabinoidRange = variant.useCannabinoidRange || companyConfig.cannabinoidDisplayType === 'range';
   *
   * This means we don't have to reference this property everywhere throughout the app. There are still some legacy
   * references, but they will eventually be phased out.
   */
  public cannabinoidDisplayType: CannabinoidDisplayType = CannabinoidDisplayType.Exact;

  /**
   * Below information only pertains to the [Display], and not [Dashboard] or [Admin]
   *
   * This signal is consolidated into every variant.useTerpeneRange before the data leaves the backend.
   * So variant.useTerpeneRange within the [Display] is actually:
   * variant.useTerpeneRange = variant.useTerpeneRange || companyConfig.terpeneDisplayType === 'range';
   *
   * This means we don't have to reference this property everywhere throughout the app. There are still some legacy
   * references, but they will eventually be phased out.
   */
  public terpeneDisplayType: TerpeneDisplayType = TerpeneDisplayType.Exact;

  // Cache
  cachedTime: number;

  static buildCacheKey(id: string): string {
    return `CompanyConfiguration-${id}`;
  }

  public onDeserialize() {
    if (!this.cannabinoidDisplayType) {
      this.cannabinoidDisplayType = CannabinoidDisplayType.Exact;
    }
    this.enabledCannabinoids = Array.from(this.enabledCannabinoids || []);
    this.enabledTerpenes = Array.from(this.enabledTerpenes || [])?.map(terp => StringUtils.toPascalCase(terp));
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneDay();
  }

  cacheKey(): string {
    return CompanyConfiguration.buildCacheKey(this.companyId.toString());
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.nowInUnixSeconds() > expiresAt;
  }

  getUniqueIdentifier(): string {
    return `
      -${this.companyId}
      -${this.restockThresholdHours}
      -${this.cannabinoidDisplayType}
      -${this.terpeneDisplayType}
      -${this.saleLabelFormat}
    `;
  }

}

