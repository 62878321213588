import { Pipe, PipeTransform } from '@angular/core';
import { ProductStylingViewModel } from '../../../models/shared/product-styling-view-model';
import { iif, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SectionColumnConfigCollectiveTerpeneKey } from '../../../models/menu/section/section-column-config';

@Pipe({
  name: 'isLowAmountTerpeneOrTAT',
  standalone: true
})
export class IsLowAmountTerpeneOrTATPipe implements PipeTransform {

  transform(productStylingViewModel: ProductStylingViewModel, terpenePascalCased: string): Observable<boolean> {
    return iif(
      () => terpenePascalCased === SectionColumnConfigCollectiveTerpeneKey.TopTerpene,
      of(false),
      productStylingViewModel.rowViewModel$.pipe(
        map(sectionRowViewModel => sectionRowViewModel?.getTerpene(terpenePascalCased)?.indexOf('<') > -1)
      )
    );
  }

}
