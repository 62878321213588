import { SectionRowViewModel } from './SectionRowViewModel';
import { StringUtils } from '../../../../../../../../../utils/string-utils';
import { SectionColumnConfigDataValue } from '../../../../../../../../../models/menu/section/section-column-config';

export class SectionRowViewModelSpaceMonkeyPrint extends SectionRowViewModel {

  override getCannabinoid(
    cannabinoid: string,
    displayFormat: SectionColumnConfigDataValue = SectionColumnConfigDataValue.NumericValue
  ): string {
    return StringUtils.removeWhiteSpace(super.getCannabinoid(cannabinoid, displayFormat));
  }

  override getTerpene(
    terpenePascalCased: string,
    displayFormat: SectionColumnConfigDataValue = SectionColumnConfigDataValue.NumericValue
  ): string {
    return StringUtils.removeWhiteSpace(super.getTerpene(terpenePascalCased, displayFormat));
  }

}
