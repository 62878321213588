import { PrintCardMenu } from './print-card-menu';
import { AssetLibraryType } from '../../enum/dto/asset-library-type.enum';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class FikaPrintCardMenu extends PrintCardMenu {

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.neueHaasGroteskDisplayRegular,
      FONT.neueHaasGroteskDisplayBold,
      FONT.neueHaasGroteskDisplayBoldItalic,
      FONT.neueHaasGroteskDisplayMedium,
      FONT.neueHaasGroteskDisplayMediumItalic,
      FONT.neueHaasGroteskDisplayRegularItalic,
      FONT.neueHaasGroteskDisplaySemiBold,
    ];
  }

  shouldFetchVariantAssets(): boolean {
    return true;
  }

  variantAssetTypeSortOrder(): string[] {
    return [
      AssetLibraryType.PrimaryBrand,
      AssetLibraryType.AlternateBrand,
      AssetLibraryType.Brand,
    ];
  }

  shouldStandardizeDominantStrainType(): boolean {
    return false;
  }

}
