import { Type } from '@angular/core';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { Section } from '../section/section';
import { Product } from '../../product/dto/product';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import type { SectionWithProducts } from '../section/section-with-products';
import { ProductMenu } from '../product-menu';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class JimmyMenu extends ProductMenu {

  getThemeClass(): string {
    return 'jimmy-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getShowHeader(): boolean {
    return true;
  }

  getShowFooter(): boolean {
    return false;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getProductSubtitle(
    section: Section,
    product: Product,
    rowVm: SectionRowViewModel
  ): string {
    const thc = rowVm.getCannabinoid('THC');
    const cbd = rowVm.getCannabinoid('CBD');
    if (thc !== '-' && cbd !== '-') {
      return `THC ${thc} \xa0\xa0 CBD ${cbd}`;
    } else {
      return '-';
    }
  }

  getProductWrapperStyling(section: SectionWithProducts, sectionRowViewModel: SectionRowViewModel, odd: boolean) {
    const rowStyling = super.getProductWrapperStyling(section, sectionRowViewModel, odd);
    rowStyling['border-top'] = `1px solid ${this.menuOptions.bodyTextColor}`;
    return rowStyling;
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.edsMarketNarrowSlant,
      FONT.futuraPtHeavy,
      FONT.futuraPtExtraBold
    ];
  }

}
