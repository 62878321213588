import { StrainType } from '../../../../../../../../../models/enum/dto/strain-classification.enum';
import { CannabisUnitOfMeasure } from '../../../../../../../../../models/enum/dto/cannabis-unit-of-measure.enum';
import { SectionItemOverflowStatus } from '../../../../../../../../../models/enum/shared/section-item-overflow-status.enum';
import { CannabinoidDisplayType } from '../../../../../../../../../models/enum/shared/cannabinoid-display-type.enum';
import { VariantType } from '../../../../../../../../../models/enum/dto/variant-type.enum';
import { ProductType } from '../../../../../../../../../models/enum/dto/product-type.enum';
import { SectionColumnConfigCollectiveTerpeneKey, SectionColumnConfigDataValue, SectionColumnConfigProductInfoKey } from '../../../../../../../../../models/menu/section/section-column-config';
import { SectionLayoutType } from '../../../../../../../../../models/enum/dto/section-layout-type.enum';
import { StringUtils } from '../../../../../../../../../utils/string-utils';
import { LabelComponentInterface } from '../../../../../../../../labels/components/label-component-interface';
import { ArrayUtils } from '../../../../../../../../../utils/array-utils';
import { StrainTypeUtils } from '../../../../../../../../../utils/strain-type-utils';
import { LocationPriceStream } from '../../../../../../../../../models/enum/shared/location-price-stream';
import { exists } from '../../../../../../../../../functions/exists';
import { VariantTypeUtils } from '../../../../../../../../../utils/variant-type-utils';
import { MenuUtils } from '../../../../../../../../../utils/menu-utils';
import type { CompanyConfiguration } from '../../../../../../../../../models/company/dto/company-configuration';
import type { LocationConfiguration } from '../../../../../../../../../models/company/dto/location-configuration';
import type { Menu } from '../../../../../../../../../models/menu/menu';
import type { MenuStyle } from '../../../../../../../../../models/menu/dto/menu-style';
import type { Product } from '../../../../../../../../../models/product/dto/product';
import type { ProductMenu } from '../../../../../../../../../models/menu/product-menu';
import { SectionColumnViewModel } from '../section-column-view-models/SectionColumnViewModel';
import type { SectionWithProducts } from '../../../../../../../../../models/menu/section/section-with-products';
import type { Subheader } from '../../../../../../../../../models/menu/section/subheader';
import type { Variant } from '../../../../../../../../../models/product/dto/variant';
import type { VariantBadge } from '../../../../../../../../../models/product/dto/variant-badge';
import { BadgeUtils } from '../../../../../../../../../utils/badge-utils';
import { TerpeneUnitOfMeasure } from '../../../../../../../../../models/enum/dto/terpene-unit-of-measure';
import { TerpeneDisplayType } from '../../../../../../../../../models/enum/shared/terpene-display-type';
import { SortUtils } from '../../../../../../../../../utils/sort-utils';
import { NumberUtils } from '../../../../../../../../../utils/number.utils';
import { Cannabinoid } from '../../../../../../../../../models/enum/shared/cannabinoid';

export class SectionRowViewModel implements LabelComponentInterface {

  menu: ProductMenu;
  section: SectionWithProducts;
  subHeader: Subheader;
  product: Product;
  rowVariants: Variant[];
  variantLineItemMode = false;
  collapseContent = false;
  hideLabel = false;
  configTheme: string;
  style: MenuStyle;
  hidePriceOnVariantIds: string[] = [];
  companyConfig: CompanyConfiguration;
  locationConfig: LocationConfiguration;
  variantBadgeMap: Map<string, VariantBadge[]> = new Map<string, VariantBadge[]>();
  itemOverflowStatus: SectionItemOverflowStatus = SectionItemOverflowStatus.NotSet;
  // used for section level snap transition
  private uniqueIdOffset = 0;

  getBrand(): string {
    return this.product?.getBrand(this.rowVariants?.map(v => v.id));
  }

  getBrandFor(variant: Variant): string {
    return variant?.brand;
  }

  getManufacturer(): string {
    const manufacturers = this.rowVariants?.filter(v => !!v?.manufacturer).map(v => v?.manufacturer).filterNulls();
    return StringUtils.getStringMode(manufacturers);
  }

  getProductSubtitle(): string {
    return this.menu?.getProductSubtitle(this.section, this.product, this);
  }

  getProductTertiaryTitle(): string {
    return this.menu?.getProductTertiaryTitle(this.section, this.product, this);
  }

  getImageContainerFit(): string {
    const config = this.section?.columnConfig?.get(SectionColumnConfigProductInfoKey.Asset);
    return config?.dataValue ?? 'contain';
  }

  getImageOpacity(): number {
    const config = this.section?.columnConfig?.get(SectionColumnConfigProductInfoKey.Asset);
    const opacity = config?.columnOpacity;
    return opacity === 0 ? 1 : opacity;
  }

  getRowTitle(): string {
    return this.menu?.getProductTitle(
      this.section,
      this.product,
      this.rowVariants,
      this.locationConfig?.priceFormat
    );
  }

  getRowTitleFor(variant: Variant): string {
    return this.menu?.getProductTitle(
      this.section,
      this.product,
      [variant],
      this.locationConfig?.priceFormat
    );
  }

  getVariantIdsSeperatedBy(separator: string): string {
    return this.rowVariants?.map(v => v.id).join(separator);
  }

  /**
   * Use getReadableStrainType if you need to display the strain type in the UI.
   */
  getStrainType(): StrainType {
    return this.variantLineItemMode
      ? (this.rowVariants?.find(v => exists(v?.classification))?.classification ?? StrainType.UNKNOWN)
      : (this.product?.classification ?? StrainType.UNKNOWN);
  }

  getReadableStrainType(
    strainTypeMode: SectionColumnConfigDataValue = SectionColumnConfigDataValue.StrainTypeWord
  ): string {
    const strainType = this.getStrainType();
    if (strainTypeMode === SectionColumnConfigDataValue.StrainTypeSymbol) {
      return StrainTypeUtils.getSymbol(strainType);
    } else {
      return StrainTypeUtils.getStandardizedName(strainType, this.menu?.shouldStandardizeDominantStrainType());
    }
  }

  getReadableStrainTypeFor(
    variant: Variant,
    strainTypeMode: SectionColumnConfigDataValue = SectionColumnConfigDataValue.StrainTypeWord
  ): string {
    const strainType = variant?.classification;
    if (strainTypeMode === SectionColumnConfigDataValue.StrainTypeSymbol) {
      return StrainTypeUtils.getSymbol(strainType);
    } else {
      return StrainTypeUtils.getStandardizedName(strainType, this.menu?.shouldStandardizeDominantStrainType());
    }
  }

  getStrainTypeColor(): string {
    switch (this.product?.classification) {
      case StrainType.Indica:
      case StrainType.IndicaDominant:
        return '#7640EF';
      case StrainType.Sativa:
      case StrainType.SativaDominant:
        return '#EF4040';
      case StrainType.Hybrid:
        return '#55C678';
      case StrainType.Blend:
        return '#6F6F6F';
      case StrainType.CBD:
        return '#4E6A9D';
      case StrainType.UNKNOWN:
        return '#222222';
      default:
        return '#222222';
    }
  }

  getMaxRowStock(): number {
    return Math.max(...this.getScopedVisibleVariantsElseRowVariants().map(v => v.inventory.quantityInStock));
  }

  getMinRowPrice(): number {
    const themeId = this.menu?.theme;
    const rowVariants = this.getScopedVisibleVariantsElseRowVariants() || [];
    const locationId = this.menu?.locationId;
    const companyId = this.menu?.companyId;
    const priceStream = this.locationConfig?.priceFormat;
    const hideSale = this.getHideSale();
    return Math.min(...rowVariants.map(v => v.getVisiblePrice(themeId, locationId, companyId, priceStream, hideSale)));
  }

  getMinRowSecondaryPrice(): number {
    const themeId = this.menu?.theme;
    const locId = this.menu?.locationId;
    const companyId = this.menu?.companyId;
    const priceStream = this.locationConfig?.priceFormat;
    const hideSale = this.getHideSale();
    const mode = this.section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice)?.dataValue;
    const rowVariants = this.getScopedVisibleVariantsElseRowVariants() || [];
    switch (mode) {
      case SectionColumnConfigDataValue.PricePerUOM:
        return Math.min(...rowVariants.map(v => v.getPricePerUOM(themeId, locId, companyId, priceStream, hideSale)));
      case SectionColumnConfigDataValue.OriginalPrice:
        return Math.min(...rowVariants.map(v => v.getPriceWithoutDiscounts(themeId, locId, companyId, priceStream)));
      case SectionColumnConfigDataValue.SaleOriginalPrice:
        const saleOriginalPrices = rowVariants
          .map(v => v.getSaleOriginalPriceOrNull(themeId, locId, companyId, priceStream))
          .filterNulls();
        return saleOriginalPrices.length > 0 ? Math.min(...saleOriginalPrices) : null;
      case SectionColumnConfigDataValue.TaxesInPrice:
        return Math.min(...rowVariants.map(v => v.getTaxesInPrice(themeId, locId, companyId)));
      case SectionColumnConfigDataValue.TaxesInRoundedPrice:
        return Math.min(...rowVariants.map(v => v.getTaxesInRoundedPrice(themeId, locId, companyId)));
      case SectionColumnConfigDataValue.PreTaxPrice:
        return Math.min(...rowVariants.map(v => v.getPreTaxPrice(themeId, locId, companyId)));
      default:
        return Math.min(...rowVariants.map(v => v.getSecondaryPrice(themeId, companyId, locId, priceStream)));
    }
  }

  getMaxRowPrice(): number {
    const themeId = this.menu?.theme;
    const locationId = this.menu?.locationId;
    const companyId = this.menu?.companyId;
    const priceStream = this.locationConfig?.priceFormat;
    const hideSale = this.getHideSale();
    const rowVariants = this.getScopedVisibleVariantsElseRowVariants() || [];
    return Math.max(...rowVariants.map(v => v.getVisiblePrice(themeId, locationId, companyId, priceStream, hideSale)));
  }

  getMaxRowSecondaryPrice(): number {
    const themeId = this.menu?.theme;
    const locId = this.menu?.locationId;
    const companyId = this.menu?.companyId;
    const priceStream = this.locationConfig?.priceFormat;
    const hideSale = this.getHideSale();
    const mode = this.section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice)?.dataValue;
    const rowVariants = this.getScopedVisibleVariantsElseRowVariants() || [];
    switch (mode) {
      case SectionColumnConfigDataValue.PricePerUOM:
        return Math.max(...rowVariants.map(v => v.getPricePerUOM(themeId, locId, companyId, priceStream, hideSale)));
      case SectionColumnConfigDataValue.OriginalPrice:
        return Math.max(...rowVariants.map(v => v.getPriceWithoutDiscounts(themeId, locId, companyId, priceStream)));
      case SectionColumnConfigDataValue.SaleOriginalPrice:
        const saleOriginalPrices = rowVariants
          .map(v => v.getSaleOriginalPriceOrNull(themeId, locId, companyId, priceStream))
          .filterNulls();
        return saleOriginalPrices.length > 0 ? Math.max(...saleOriginalPrices) : null;
      case SectionColumnConfigDataValue.TaxesInPrice:
        return Math.max(...rowVariants.map(v => v.getTaxesInPrice(themeId, locId, companyId)));
      case SectionColumnConfigDataValue.TaxesInRoundedPrice:
        return Math.max(...rowVariants.map(v => v.getTaxesInRoundedPrice(themeId, locId, companyId)));
      case SectionColumnConfigDataValue.PreTaxPrice:
        return Math.max(...rowVariants.map(v => v.getPreTaxPrice(themeId, locId, companyId)));
      default:
        return Math.max(...rowVariants.map(v => v.getSecondaryPrice(themeId, companyId, locId, priceStream)));
    }
  }

  /**
   * -1 is used internally to represent a missing value or invalid value for min/max calculations.
   *
   * We filter out negative values from the min/max calculations so the following example works:
   *
   *  a) CBD - min: 3%, max: 7%
   *  b) CBD - min: 1%, max: 6%
   *  c) CBD - min: empty, max: empty
   *
   *  Outcome would be min: 1%, max: 7%. If we didn't filter out -1, the outcome would be '-'
   */
  protected getRowRangeCannabinoid(
    cannabinoid: string,
    displayFormat: SectionColumnConfigDataValue,
    fixedPoint?: number,
    variant?: Variant
  ): string {
    let minVal: number;
    let maxVal: number;
    const variants = variant ? [variant] : this.getScopedVisibleVariantsElseRowVariants();
    if (this.companyConfig?.cannabinoidDisplayType === CannabinoidDisplayType.Exact) {
      const [rangeVariants, exactVariants] = ArrayUtils.partition(variants, (v) => v?.useCannabinoidRange ?? false);
      const exactValues = exactVariants?.map(v => v?.getNumericCannabinoidOrTerpene(cannabinoid)) ?? [];
      const rangeMinValues = rangeVariants?.map(v => v?.getNumericMinCannabinoidOrTerpene(cannabinoid)) ?? [];
      const rangeMaxValues = rangeVariants?.map(v => v?.getNumericMaxCannabinoidOrTerpene(cannabinoid)) ?? [];
      const minValues = [...exactValues, ...rangeMinValues].filter(v => v >= 0);
      const maxValues = [...exactValues, ...rangeMaxValues].filter(v => v >= 0);
      minVal = Math.min(...minValues);
      maxVal = Math.max(...maxValues);
    } else {
      const min = variants?.map(v => v?.getNumericMinCannabinoidOrTerpene(cannabinoid))?.filter(v => v >= 0) ?? [];
      const max = variants?.map(v => v?.getNumericMaxCannabinoidOrTerpene(cannabinoid))?.filter(v => v >= 0) ?? [];
      minVal = Math.min(...min);
      maxVal = Math.max(...max);
    }
    // convert infinity to -1 (-1 represents invalid or empty value)
    if (!Number.isFinite(minVal)) minVal = -1;
    if (!Number.isFinite(maxVal)) maxVal = -1;
    const thcOrCbd = cannabinoid === Cannabinoid.THC
      || cannabinoid === Cannabinoid.CBD
      || cannabinoid === Cannabinoid.THCAndCBD;
    return this.getFixedPointRangeStringNoUnits(minVal, maxVal, displayFormat, fixedPoint, !thcOrCbd);
  }

  /**
   * -1 is used to represent a missing value or invalid value
   */
  getMinRowCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased: string, variant?: Variant): number {
    const minValues = (variant ? [variant] : this.getScopedVisibleVariantsElseRowVariants())
      .map(v => {
        return v.useCannabinoidRange
          ? v.getNumericMinCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased)
          : v.getNumericCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased);
      })
      .filter(v => v >= 0);
    let min = Math.min(...minValues);
    // convert infinity to -1 (-1 represents invalid or empty value)
    if (!Number.isFinite(min)) min = -1;
    return min;
  }

  /**
   * -1 is used internally to represent a missing value or invalid value for min/max calculations.
   *
   * We filter out negative values from the min/max calculations so the following example works:
   *
   *  a) Alpha Bisabolol - min: 3%, max: 7%
   *  b) Alpha Bisabolol - min: 1%, max: 6%
   *  c) Alpha Bisabolol - min: empty, max: empty
   *
   *  Outcome would be min: 1%, max: 7%. If we didn't filter out -1, the outcome would be '-'
   */
  protected getRowRangeTerpene(
    terpeneCamelCased: string,
    displayFormat: SectionColumnConfigDataValue,
    fixedPoint?: number,
    variant?: Variant
  ): string {
    let minVal: number;
    let maxVal: number;
    const variants = variant ? [variant] : this.getScopedVisibleVariantsElseRowVariants();
    if (this.companyConfig?.terpeneDisplayType === TerpeneDisplayType.Exact) {
      const [rangeVariants, exactVariants] = ArrayUtils.partition(variants, (v) => v?.useTerpeneRange ?? false);
      const exactValues = exactVariants?.map(v => v?.getNumericCannabinoidOrTerpene(terpeneCamelCased)) ?? [];
      const rangeMinValues = rangeVariants?.map(v => v?.getNumericMinCannabinoidOrTerpene(terpeneCamelCased)) ?? [];
      const rangeMaxValues = rangeVariants?.map(v => v?.getNumericMaxCannabinoidOrTerpene(terpeneCamelCased)) ?? [];
      const minValues = [...exactValues, ...rangeMinValues].filter(v => v >= 0);
      const maxValues = [...exactValues, ...rangeMaxValues].filter(v => v >= 0);
      minVal = Math.min(...minValues);
      maxVal = Math.max(...maxValues);
    } else {
      const min = variants?.map(v => v?.getNumericMinCannabinoidOrTerpene(terpeneCamelCased))?.filter(v => v >= 0);
      const max = variants?.map(v => v?.getNumericMaxCannabinoidOrTerpene(terpeneCamelCased))?.filter(v => v >= 0);
      minVal = Math.min(...(min || []));
      maxVal = Math.max(...(max || []));
    }
    // convert infinity to -1 (-1 represents invalid or empty value)
    if (!Number.isFinite(minVal)) minVal = -1;
    if (!Number.isFinite(maxVal)) maxVal = -1;
    return this.getFixedPointRangeStringNoUnits(minVal, maxVal, displayFormat, fixedPoint);
  }

  /**
   * -1 is used to represent a missing value or invalid value
   */
  getMaxRowCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased: string): number {
    const maxValues = this.getScopedVisibleVariantsElseRowVariants()
      .map(v => {
        return v.useCannabinoidRange
          ? v.getNumericMaxCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased)
          : v.getNumericCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased);
      })
      .filter(v => v >= 0);
    const max = Math.max(...maxValues);
    // convert infinity to -1 (-1 represents invalid or empty value)
    if (!Number.isFinite(max)) return -1;
    return max;
  }

  /**
   * -1 is used to represent a missing value or invalid value
   */
  getMinRowTAC(variant?: Variant): number {
    const minValues = (variant ? [variant] : this.getScopedVisibleVariantsElseRowVariants())
      .map(v => {
        return v.useCannabinoidRange
          ? v.getNumericMinTAC(this.companyConfig?.enabledCannabinoids)
          : v.getNumericTAC(this.companyConfig?.enabledCannabinoids);
      })
      .filter(v => v >= 0);
    let min = Math.min(...minValues);
    // convert infinity to -1 (-1 represents invalid or empty value)
    if (!Number.isFinite(min)) min = -1;
    return min;
  }

  /**
   * -1 is used to represent a missing value or invalid value
   */
  getMaxRowTAC(variant?: Variant): number {
    const maxValues = (variant ? [variant] : this.getScopedVisibleVariantsElseRowVariants())
      .map(v => {
        return v.useCannabinoidRange
          ? v.getNumericMaxTAC(this.companyConfig?.enabledCannabinoids)
          : v.getNumericTAC(this.companyConfig?.enabledCannabinoids);
      })
      .filter(v => v >= 0);
    let max = Math.max(...maxValues);
    // convert infinity to -1 (-1 represents invalid or empty value)
    if (!Number.isFinite(max)) max = -1;
    return max;
  }

  /**
   * -1 is used to represent a missing value or invalid value
   */
  getMinRowTAT(variant?: Variant): number {
    const minValues = (variant ? [variant] : this.getScopedVisibleVariantsElseRowVariants())
      .map(v => {
        return v.useTerpeneRange
          ? v.getNumericMinTAT(this.companyConfig?.enabledTerpenes)
          : v.getNumericTAT(this.companyConfig?.enabledTerpenes);
      })
      .filter(v => v >= 0);
    let min = Math.min(...minValues);
    // convert infinity to -1 (-1 represents invalid or empty value)
    if (!Number.isFinite(min)) min = -1;
    return min;
  }

  /**
   * -1 is used to represent a missing value or invalid value
   */
  getMaxRowTAT(variant?: Variant): number {
    const maxValues = (variant ? [variant] : this.getScopedVisibleVariantsElseRowVariants())
      .map(v => {
        return v.useTerpeneRange
          ? v.getNumericMaxTAT(this.companyConfig?.enabledTerpenes)
          : v.getNumericTAT(this.companyConfig?.enabledTerpenes);
      })
      .filter(v => v >= 0);
    let max = Math.max(...maxValues);
    // convert infinity to -1 (-1 represents invalid or empty value)
    if (!Number.isFinite(max)) max = -1;
    return max;
  }

  getTopTerpene(): string {
    return this.getScopedVisibleVariantsElseRowVariants()
      ?.map(v => v?.getTopTerpene(this.companyConfig?.enabledTerpenes))
      ?.filterNulls()
      ?.mode() ?? '-';
  }

  /**
   * The original thc/cbd cannabinoid logic displays numbers less than 1 as "<1", so
   * displayEntireNumberIfItIsLessThanOne was added to keep this old logic for thc/cbd,
   * but for all the new "secondary" cannabinoids and terpenes, they always display as the full number.
   */
  protected getFixedPointRangeStringNoUnits(
    minVal: number,
    maxVal: number,
    displayFormat: SectionColumnConfigDataValue,
    fixedPoint: number | undefined | null,
    displayEntireNumberIfItIsLessThanOne = true
  ): string {
    if (displayFormat === SectionColumnConfigDataValue.PresenceIcon) {
      return (minVal > 0 || maxVal > 0) ? '✔' : '-';
    }
    if (minVal === -1 || maxVal === -1) return '';
    if (minVal === maxVal) {
      if (!displayEntireNumberIfItIsLessThanOne && minVal < 1) {
        return String('<1').trim();
      }
      // ie, don't return 14-14, just return 14,
      return fixedPoint ? minVal.toFixed(fixedPoint) : `${minVal}`;
    }
    return fixedPoint
      ? (minVal.toFixed(fixedPoint) + ' - ' + maxVal.toFixed(fixedPoint))
      : `${minVal} - ${maxVal}`;
  }

  getMinRowNumericSize(): number {
    return Math.min(...this.getScopedVisibleVariantsElseRowVariants().map(v => v.getNumericSize()));
  }

  getMaxRowNumericSize(): number {
    return Math.max(...this.getScopedVisibleVariantsElseRowVariants().map(v => v.getNumericSize()));
  }

  protected getEmptyStateForCannabinoid(variants: Variant[]): string | null {
    const firstVariant = variants?.firstOrNull();
    const displayCannabinoid = firstVariant?.shouldDisplayCannabinoidValue();
    const cuomIsNA = firstVariant?.cannabisUnitOfMeasure === CannabisUnitOfMeasure.NA;
    switch (true) {
      case !firstVariant:       return '';
      case !displayCannabinoid: return '-';
      case cuomIsNA:            return '--';
    }
    return null;
  }

  protected hasPresentValueElseEmptyState(value: string): string {
    switch (value) {
      case undefined:
      case null:
      case '':
      case '-':
      case '--':
      case '0':
        return '-';
      default:
        return '✔';
    }
  }

  /**
   * getTAC is called in here because there are a lot of places in the app that override getCannabinoid to remove
   * spaces and other characters. Therefore, this prevents searching through the code base for all the getCannabinoid
   * overrides and implementing the same logic for getTAC.
   */
  public getCannabinoid(
    cannabinoid: string,
    displayFormat: SectionColumnConfigDataValue = SectionColumnConfigDataValue.NumericValue
  ): string {
    if (cannabinoid === 'TAC') return this.getTAC();
    const variants = this.getScopedVisibleVariantsElseRowVariants();
    const emptyState = this.getEmptyStateForCannabinoid(variants);
    if (emptyState !== null) return emptyState;
    const unitOfMeasureString = variants?.firstOrNull()?.getCannabinoidUnitOfMeasureString(this.menu);
    const cannabinoidValueAsString = this.displayCannabinoidInRanges()
      ? this.getCannabinoidRowRangeAsString(cannabinoid, unitOfMeasureString, displayFormat)
      : this.getCannabinoidAsString(cannabinoid, unitOfMeasureString, displayFormat);
    if (displayFormat === SectionColumnConfigDataValue.PresenceIcon) {
      return this.hasPresentValueElseEmptyState(cannabinoidValueAsString);
    }
    return cannabinoidValueAsString;
  }

  protected getCannabinoidRowRangeAsString(
    cannabinoid: string,
    unitOfMeasureString: string,
    displayFormat: SectionColumnConfigDataValue = SectionColumnConfigDataValue.NumericValue,
    fixedPoint?: number,
    variant?: Variant
  ): string {
    const range = this.getRowRangeCannabinoid(cannabinoid, displayFormat, fixedPoint, variant);
    switch (true) {
      case displayFormat === SectionColumnConfigDataValue.PresenceIcon:
        return range;
      case !range:
        return `-`;
      default:
        return `${range} ${this.showCUOMInHeader() ? '' : unitOfMeasureString}`.trim();
    }
  }

  protected getCannabinoidAsString(
    cannabinoid: string,
    units: string,
    displayFormat: SectionColumnConfigDataValue = SectionColumnConfigDataValue.NumericValue,
    fixedPoint?: number,
    variant?: Variant
  ): string {
    const parsedCannabinoid = this.getMinRowCannabinoidOrTerpene(cannabinoid, variant);
    switch (true) {
      case displayFormat === SectionColumnConfigDataValue.PresenceIcon: {
        return parsedCannabinoid > 0 ? '✔' : '-';
      }
      case !Number.isFinite(parsedCannabinoid):
      case parsedCannabinoid < 0: {
        return '-';
      }
      case parsedCannabinoid < 1 && (cannabinoid === Cannabinoid.THC || cannabinoid === Cannabinoid.CBD): {
        return (this.showCUOMInHeader() ? `<1` : `<1 ${units}`).trim();
      }
      case Number.isFinite(fixedPoint): {
        const fixed = `${parsedCannabinoid.toFixed(fixedPoint)} `;
        const addUnits = `${this.showCUOMInHeader() ? '' : units}`;
        return (fixed + addUnits).trim();
      }
      default: {
        const rounded = `${(Math.round((parsedCannabinoid + Number.EPSILON) * 100) / 100)} `;
        const addUnits = `${this.showCUOMInHeader() ? '' : units}`;
        return (rounded + addUnits).trim();
      }
    }
  }

  /**
   * TAC stands for Total Active Cannabinoids.
   *
   * If you want to shape cannabinoids and TAC the same way, then you only need to override getCannabinoid, because
   * getCannabinoid calls getTAC internally, and is used to display getTAC in the UI.
   *
   * For most cases, use getCannabinoid('TAC') instead of getTAC, due to getCannabinoid being overridden in many places
   * to change the output formatting of cannabinoids.
   */
  getTAC(): string {
    const variants = this.getScopedVisibleVariantsElseRowVariants();
    const emptyState = this.getEmptyStateForCannabinoid(variants);
    if (emptyState !== null) return emptyState;
    const enabledSecondaryCannabinoids = this.companyConfig?.enabledCannabinoids || [];
    const unitOfMeasureString = variants?.firstOrNull()?.getCannabinoidUnitOfMeasureString(this.menu);
    let val: string = '';
    const totalActiveCannabinoids = variants
      ?.flatMap(v => {
        return v.useCannabinoidRange
          ? [v.getNumericMinTAC(enabledSecondaryCannabinoids), v.getNumericMaxTAC(enabledSecondaryCannabinoids)]
          : [v.getNumericTAC(enabledSecondaryCannabinoids)];
      })
      ?.filter(tat => Number.isFinite(tat))
      ?.map(tat => NumberUtils.roundToTwoDecimalPlaces(tat))
      ?.unique(false)
      ?.sort(SortUtils.numberAscending);
    if (totalActiveCannabinoids?.length >= 2) {
      val = `${totalActiveCannabinoids?.firstOrNull()} - ${totalActiveCannabinoids?.last()}`;
    } else if (totalActiveCannabinoids?.length === 1) {
      val = `${totalActiveCannabinoids?.firstOrNull()}`;
    }
    if (!val) return `-`;
    return `${val} ${this.showCUOMInHeader() ? '' : unitOfMeasureString}`.trim();
  }

  protected getEmptyStateForTerpene(variants: Variant[]): string | null {
    const firstVariant = variants?.firstOrNull();
    const displayTerpene = firstVariant?.shouldDisplayTerpeneValue();
    const tuomIsNA = firstVariant?.terpeneUnitOfMeasure === TerpeneUnitOfMeasure.NA;
    switch (true) {
      case !firstVariant:   return '';
      case !displayTerpene: return '-';
      case tuomIsNA:        return '--';
    }
    return null;
  }

  getTerpene(
    terpenePascalCased: string,
    displayFormat: SectionColumnConfigDataValue = SectionColumnConfigDataValue.NumericValue
  ): string {
    if (terpenePascalCased === SectionColumnConfigCollectiveTerpeneKey.TotalTerpene) return this.getTAT();
    const variants = this.getScopedVisibleVariantsElseRowVariants();
    const emptyState = this.getEmptyStateForTerpene(variants);
    if (emptyState !== null) return emptyState;
    const unitOfMeasureString = variants?.firstOrNull()?.getTerpeneUnitOfMeasureString(this.menu);
    const terpeneValueAsString = this.displayTerpeneInRanges()
      ? this.getTerpeneRowRangeAsString(terpenePascalCased, unitOfMeasureString, displayFormat)
      : this.getTerpeneAsString(terpenePascalCased, unitOfMeasureString, displayFormat);
    if (displayFormat === SectionColumnConfigDataValue.PresenceIcon) {
      return this.hasPresentValueElseEmptyState(terpeneValueAsString);
    }
    return terpeneValueAsString;
  }

  protected getTerpeneRowRangeAsString(
    terpenePascalCased: string,
    unitOfMeasureString: string,
    displayFormat: SectionColumnConfigDataValue,
    fixedPoint?: number
  ): string {
    const terpeneCamelCased = StringUtils.pascalCaseToCamelCase(terpenePascalCased);
    const range = this.getRowRangeTerpene(terpeneCamelCased, displayFormat, fixedPoint);
    switch (true) {
      case displayFormat === SectionColumnConfigDataValue.PresenceIcon:
        return range;
      case !range:
        return `-`;
      default:
        return `${range} ${this.showTUOMInHeader() ? '' : unitOfMeasureString}`.trim();
    }
  }

  protected getTerpeneAsString(
    terpenePascalCased: string,
    units: string,
    displayFormat: SectionColumnConfigDataValue,
    fixedPoint?: number,
  ): string {
    const terpeneCamelCased = StringUtils.pascalCaseToCamelCase(terpenePascalCased);
    const parsedTerpene = this.getMinRowCannabinoidOrTerpene(terpeneCamelCased);
    switch (true) {
      case displayFormat === SectionColumnConfigDataValue.PresenceIcon: {
        return parsedTerpene > 0 ? '✔' : '-';
      }
      case !Number.isFinite(parsedTerpene):
      case parsedTerpene < 0: {
        return '-';
      }
      case Number.isFinite(fixedPoint): {
        const fixed = `${parsedTerpene.toFixed(fixedPoint)} `;
        const addUnits = `${this.showTUOMInHeader() ? '' : units}`;
        return (fixed + addUnits).trim();
      }
      default: {
        const rounded = `${(Math.round((parsedTerpene + Number.EPSILON) * 100) / 100)} `;
        const addUnits = `${this.showTUOMInHeader() ? '' : units}`;
        return (rounded + addUnits).trim();
      }
    }
  }

  /**
   * TAT stands for Total Active Terpenes.
   *
   * If you want to shape terpenes and TAT the same way, then you only need to override getTerpene, because
   * getTerpene calls getTAT internally, and is used to display getTAT in the UI.
   *
   * For most cases, use getTerpene('TotalTerpene') instead of getTAT, due to getTerpene being overridden in many places
   * to change the output formatting of terpenes.
   */
  public getTAT(): string {
    const variants = this.getScopedVisibleVariantsElseRowVariants();
    const emptyState = this.getEmptyStateForTerpene(variants);
    if (emptyState !== null) return emptyState;
    const enabledTerpenesPascalCased = this.companyConfig?.enabledTerpenes || [];
    const unitOfMeasureString = variants?.firstOrNull()?.getTerpeneUnitOfMeasureString(this.menu);
    let val: string = '';
    const totalTerpenes = variants
      ?.flatMap(v => {
        return v.useTerpeneRange
          ? [v.getNumericMinTAT(enabledTerpenesPascalCased), v.getNumericMaxTAT(enabledTerpenesPascalCased)]
          : [v.getNumericTAT(enabledTerpenesPascalCased)];
      })
      ?.filter(tat => Number.isFinite(tat))
      ?.unique(false)
      ?.sort(SortUtils.numberAscending);
    if (totalTerpenes?.length >= 2) {
      val = `${totalTerpenes?.firstOrNull()} - ${totalTerpenes?.last()}`;
    } else if (totalTerpenes?.length === 1) {
      val = `${totalTerpenes?.firstOrNull()}`;
    }
    if (!val) return `-`;
    return `${val} ${this.showTUOMInHeader() ? '' : unitOfMeasureString}`.trim();
  }

  /**
   * @deprecated use getCannabinoid instead
   */
  public getThc(): string {
    const variants = this.getScopedVisibleVariantsElseRowVariants();
    if (variants.length > 0) {
      const shouldDisplayTHC = variants?.firstOrNull()?.shouldDisplayCannabinoidValue();
      if (shouldDisplayTHC) {
        if (variants?.firstOrNull().cannabisUnitOfMeasure === CannabisUnitOfMeasure.NA) {
          return '--';
        }
        const unitOfMeasureString = variants?.firstOrNull()?.getCannabinoidUnitOfMeasureString(this.menu);
        // Get THC value
        if (this.displayCannabinoidInRanges()) {
          return this.getThcRowRangeAsString(unitOfMeasureString);
        } else {
          return this.getThcAsString(unitOfMeasureString);
        }
      } else {
        return '-';
      }
    }
    return '';
  }

  /**
   * @deprecated use getCannabinoidRowRangeAsString instead
   */
  protected getThcRowRangeAsString(unitOfMeasureString: string, fixedPoint?: number, variant?: Variant): string {
    const thcRange = this.getRowRangeCannabinoid(
      'THC',
      SectionColumnConfigDataValue.NumericValue,
      fixedPoint,
      variant
    );
    if (thcRange === '') {
      return `${CannabisUnitOfMeasure.NA}`;
    } else {
      return `${thcRange} ${this.showCUOMInHeader() ? '' : unitOfMeasureString}`.trim();
    }
  }

  /**
   * @deprecated use getCannabinoidAsString instead
   */
  protected getThcAsString(units: string, fixedPoint?: number, variant?: Variant): string {
    const parsedThc = this.getMinRowCannabinoidOrTerpene('THC', variant);
    if (parsedThc < 1) {
      return (this.showCUOMInHeader() ? `<1` : `<1 ${units}`).trim();
    } else {
      if (fixedPoint) {
        return `${parsedThc.toFixed(fixedPoint)} ${this.showCUOMInHeader() ? '' : units}`.trim();
      } else {
        const roundedThc = `${(Math.round((parsedThc + Number.EPSILON) * 100) / 100)} `
          + `${this.showCUOMInHeader() ? '' : units}`;
        return roundedThc.trim();
      }
    }
  }

  /**
   * @deprecated use getCannabinoid instead
   */
  public getCbd(): string {
    const variants = this.getScopedVisibleVariantsElseRowVariants();
    if (variants.length > 0) {
      const shouldDisplayCBD = variants?.firstOrNull()?.shouldDisplayCannabinoidValue();
      if (shouldDisplayCBD) {
        if (variants?.firstOrNull().cannabisUnitOfMeasure === CannabisUnitOfMeasure.NA) {
          return '--';
        }
        const unitOfMeasureString = variants?.firstOrNull()?.getCannabinoidUnitOfMeasureString(this.menu);
        // Get CBD Value
        if (this.displayCannabinoidInRanges()) {
          return this.getCbdRowRangeAsString(unitOfMeasureString);
        } else {
          return this.getCbdAsString(unitOfMeasureString);
        }
      } else {
        return '-';
      }
    }
    return '';
  }

  /**
   * @deprecated use getCannabinoidRowRangeAsString instead
   */
  protected getCbdRowRangeAsString(unitOfMeasureString: string, fixedPoint?: number, variant?: Variant): string {
    const cbdRange = this.getRowRangeCannabinoid(
      'CBD',
      SectionColumnConfigDataValue.NumericValue,
      fixedPoint,
      variant
    );
    if (cbdRange === '') {
      return `${CannabisUnitOfMeasure.NA}`;
    } else {
      return `${cbdRange} ${this.showCUOMInHeader() ? '' : unitOfMeasureString}`.trim();
    }
  }

  /**
   * @deprecated use getCannabinoidAsString instead
   */
  protected getCbdAsString(units: string, fixedPoint?: number, variant?: Variant): string {
      const parsedCbd = this.getMinRowCannabinoidOrTerpene('CBD', variant);
    if (parsedCbd < 1) {
      return (this.showCUOMInHeader() ? `<1` : `<1 ${units}`).trim();
    } else {
      if (fixedPoint) {
        return `${parsedCbd.toFixed(fixedPoint)} ${this.showCUOMInHeader() ? '' : units}`.trim();
      } else {
        const roundedCbd = `${(Math.round((parsedCbd + Number.EPSILON) * 100) / 100)} `
          + `${this.showCUOMInHeader() ? '' : units}`;
        return roundedCbd.trim();
      }
    }
  }

  public getCUOM(): string {
    return this.rowVariants?.firstOrNull()?.getCannabinoidUnitOfMeasureString(this.menu);
  }

  public getRowUniqueCUOM(): CannabisUnitOfMeasure {
    return this.rowVariants?.map(v => v.cannabisUnitOfMeasure)?.uniqueInstance(true) ?? CannabisUnitOfMeasure.UNKNOWN;
  }

  public getRowUniqueTUOM(): TerpeneUnitOfMeasure {
    return this.rowVariants?.map(v => v.terpeneUnitOfMeasure)?.uniqueInstance(true) ?? TerpeneUnitOfMeasure.UNKNOWN;
  }

  public getSectionUniqueCUOM(): CannabisUnitOfMeasure {
    const sectionCUOMs = this.section
      ?.getScopedVisibleVariants(this.menu, this.locationConfig?.priceFormat, this.menu?.isSectionLevelOverflow())
      ?.map(v => v.cannabisUnitOfMeasure);
    return sectionCUOMs.uniqueInstance(true) ?? CannabisUnitOfMeasure.UNKNOWN;
  }

  public getSectionUniqueTUOM(): TerpeneUnitOfMeasure {
    const sectionTUOMs = this.section
      ?.getScopedVisibleVariants(this.menu, this.locationConfig?.priceFormat, this.menu?.isSectionLevelOverflow())
      ?.map(v => v.terpeneUnitOfMeasure);
    return sectionTUOMs.uniqueInstance(true) ?? TerpeneUnitOfMeasure.UNKNOWN;
  }

  displayCannabinoidInRanges(): boolean {
    const companyUsesCannabinoidRange = this.companyConfig?.cannabinoidDisplayType === CannabinoidDisplayType.Range;
    const someRowVariantsUseCannabinoidRange = () => {
      return this.getScopedVisibleVariantsElseRowVariants()?.some(v => v.useCannabinoidRange);
    };
    return companyUsesCannabinoidRange || someRowVariantsUseCannabinoidRange();
  }

  displayTerpeneInRanges(): boolean {
    const companyUsesTerpeneRange = this.companyConfig?.terpeneDisplayType === TerpeneDisplayType.Range;
    const someRowVariantsUseTerpeneRange = () => {
      return this.getScopedVisibleVariantsElseRowVariants()?.some(v => v?.useTerpeneRange);
    };
    return companyUsesTerpeneRange || someRowVariantsUseTerpeneRange();
  }

  public showCUOMInHeader(): boolean {
    const uniqueCUOM = this.getSectionUniqueCUOM();
    return this.menu?.menuOptions?.showCUOMInHeader && !!uniqueCUOM;
  }

  public showTUOMInHeader(): boolean {
    const uniqueTUOM = this.getSectionUniqueTUOM();
    // showTUOMInHeader doesn't exist, use showCUOMInHeader instead
    return this.menu?.menuOptions?.showCUOMInHeader && !!uniqueTUOM;
  }

  public getQuantityInStock(): number {
    return this.rowVariants?.firstOrNull()?.inventory?.quantityInStock ?? 0;
  }

  public getQuantityAndSizeString(): string {
    const productType = this.rowProductType();
    const isEdible = productType === ProductType.Edible;
    const isSeed = productType === ProductType.Seed;
    const isReadyToDrinkBeverage = VariantTypeUtils.isReadyToDrinkBeverageType(this.rowVariantType());
    switch (true) {
      case (isEdible && !isReadyToDrinkBeverage):
        return this.getQuantityAndSizeHelperForEdiblesThatAreNotReadyToDrinkBeverages();
      case isSeed:
        return this.getQuantityAndSizeHelperForSeeds();
      default:
        const quantity = this.getQuantityString();
        const size = this.getSize();
        return (!size || size === '-' || size === '--')
          ? size
          : [quantity, size].filter(s => !!s).join(' x ');
    }
  }

  protected getQuantityAndSizeHelperForSeeds(): string | null {
    const packageQuantity = this.getMinPackageQuantity();
    return `${packageQuantity} ${this.seedPackageQuantityWord()}`;
  }

  protected getQuantityAndSizeHelperForEdiblesThatAreNotReadyToDrinkBeverages(): string | null {
    const packageQuantity = this.getMinPackageQuantity();
    return `${packageQuantity} ${this.edibleNonReadyToDrinkPackageQuantityWord()}`;
  }

  protected edibleNonReadyToDrinkPackageQuantityWord(variant?: Variant): string {
    return 'Pack';
  }

  protected seedPackageQuantityWord(variant?: Variant): string {
    return 'Pack';
  }

  public getQuantityString(variant?: Variant): string {
    return this.getQuantity(variant).toString();
  }

  public getQuantity(variant?: Variant): number {
    if (exists(variant)) {
      return variant?.packagedQuantity;
    } else if (this.variantLineItemMode) {
      return this.rowVariants?.firstOrNull()?.packagedQuantity;
    } else {
      return 1;
    }
  }

  public getGridQuantity(variant?: Variant) {
    if (exists(variant)) {
      return variant?.packagedQuantity;
    } else if (this.variantLineItemMode) {
      return this.rowVariants?.firstOrNull()?.packagedQuantity;
    } else {
      return 1;
    }
  }

  public getMinPackageQuantity(): number {
    const packageQuantity = this.rowVariants?.map(v => v?.packagedQuantity) || [];
    const min = Math.min(...packageQuantity);
    return Number.isFinite(min) ? min : 0;
  }

  public getMaxPackageQuantity(): number {
    const packageQuantity = this.rowVariants?.map(v => v?.packagedQuantity) || [];
    return Math.max(...packageQuantity, 0);
  }

  /**
   * Returns the minimum unit size of the variants in the group.
   * Can return Infinity if unit sizes are not specified.
   */
  public getMinNumericUnitSize(): number {
    const size = this.rowVariants?.map(v => v?.unitSize) || [];
    return Math.min(...size);
  }

  public getMaxNumericUnitSize(): number {
    const size = this.rowVariants?.map(v => v?.unitSize) || [];
    return Math.max(...size, 0);
  }

  public getSize(variant?: Variant): string {
    if (exists(variant)) {
      return variant?.getFormattedUnitSize();
    } else if (this.variantLineItemMode) {
      return this.rowVariants?.firstOrNull()?.getFormattedUnitSize();
    } else {
      return '';
    }
  }

  public hasBadges(): boolean {
    return this.getAllVariantBadges()?.length > 0;
  }

  public hasBrands(): boolean {
    return this.rowVariants?.firstOrNull()?.brand?.length > 0;
  }

  public getBadgesFor(variant: Variant): VariantBadge[] {
    return BadgeUtils.getVariantVisibleBadges(this.menu, this.section, [variant]);
  }

  public getAllVariantBadges(): VariantBadge[] {
    return BadgeUtils.getVariantVisibleBadges(this.menu, this.section, this.getScopedVisibleVariants());
  }

  rowStrainType(): StrainType {
    return this.rowVariants?.find(v => exists(v?.classification))?.classification;
  }

  rowProductType(): ProductType | null {
    return this.rowVariants?.find(v => exists(v?.productType))?.productType;
  }

  rowVariantType(): VariantType | null {
    return this.rowVariants?.find(v => exists(v?.variantType))?.variantType;
  }

  getHideSale(): boolean {
    return this.menu?.menuOptions?.hideSale;
  }

  hideOverflowItem(): boolean {
    return this.itemOverflowStatus === SectionItemOverflowStatus.Overflow;
  }

  isAccessory(): boolean {
    return this.rowVariants?.some(variant => variant?.productType === ProductType.Accessories);
  }

  isVariantAnAccessory(variant: Variant): boolean {
    return variant?.productType === ProductType.Accessories;
  }

  public isNonCannabinoidOtherVariant(): boolean {
    return this.rowProductType() === ProductType.Other
      && !VariantTypeUtils.isOtherTypeWithCannabinoids(this.rowVariantType());
  }

  isVariantNonCannabinoidOtherVariant(variant: Variant): boolean {
    return variant?.productType === ProductType.Other
        && !VariantTypeUtils.isOtherTypeWithCannabinoids(variant?.variantType);
  }

  public isNonCannabinoidVariant(): boolean {
    return this.isNonCannabinoidOtherVariant() || this.isAccessory();
  }

  public inStockRowVariants(): Variant[] {
    return this.variantLineItemMode ? this.rowVariants : this.rowVariants?.filter(v => v.inventory.inStock());
  }

  public getVariantMatch(columnVM: SectionColumnViewModel): Variant {
    return this.getVariantFromGridColumn(columnVM);
  }

  public showFullDescription(): boolean {
    return this.menu?.menuOptions?.showFullDescription ?? false;
  }

  getProductDesc(): string {
    if (this.menu?.menuOptions?.hideDescription) {
      return null;
    } else if (this.menu?.menuOptions?.showFullDescription) {
      return this.getScopedVisibleVariants()?.map(v => v?.description)?.filterNulls()?.firstOrNull();
    } else {
      return this.getScopedVisibleVariants()?.map(v => v?.shortDescription)?.filterNulls()?.firstOrNull()
          || this.getScopedVisibleVariants()?.map(v => v?.description)?.filterNulls()?.firstOrNull();
    }
  }

  getRichTextDescription(): string {
    if (this.menu?.menuOptions?.hideDescription || !this.menu?.menuOptions?.showFullDescription) {
      // If the description is hidden, or not set to use the full description, return null
      return null;
    } else {
      return this.getScopedVisibleVariants()?.map(v => v?.richTextDescription)?.filterNulls()?.firstOrNull();
    }
  }

  /**
   * Is the variant scoped to the currently selected grid columns and is the variant price visible and greater than 0?
   * Then the variant is scoped and visible, so add it to the list of returned variants.
   */
  getScopedVisibleVariants(): Variant[] {
    if (this.variantLineItemMode || this.section?.isChildVariantListMode()) {
      return this.rowVariants;
    } else {
      const themeId = this.menu?.theme;
      const locationId = this.menu?.locationId;
      const companyId = this.menu?.companyId;
      const priceStream = this.locationConfig?.priceFormat;
      const hideSale = this.getHideSale();
      return this.rowVariants?.filter(variant => {
        const isWithinGridScope = this.section?.isVariantWithinGridScope(variant, this.section?.layoutType, locationId);
        const hidePrice = !!this?.hidePriceOnVariantIds?.find(id => id === variant?.id);
        let price: number;
        // always show out of stock products for print card menus
        const showZeroStockItems = MenuUtils.isPrintCardMenu(this.menu) || this.section?.showZeroStockItems;
        if (showZeroStockItems || variant?.inventory?.inStock()) {
          price = variant?.getVisiblePrice(themeId, locationId, companyId, priceStream, hideSale);
        }
        return (!isWithinGridScope || hidePrice) ? false : price > 0;
      });
    }
  }

  /**
   * Are there any scoped & visible variants? Then return them, else return this.rowVariants.
   */
  getScopedVisibleVariantsElseRowVariants(): Variant[] {
    const visibleAndHavePrice = this.getScopedVisibleVariants();
    return visibleAndHavePrice?.length > 0 ? visibleAndHavePrice : this.rowVariants;
  }

  getLayoutType(): SectionLayoutType {
    return this.section?.layoutType;
  }

  incrementUniqueIdOffset(): void {
    this.uniqueIdOffset++;
  }

  uniqueId(): string {
    return this.rowVariants?.map(v => v.id)?.sort()?.join(',');
  }

  /**
   * This is needed for section level snap transitions.
   *
   * Problem: when rowCount === rowViewModels?.length + 1, trackBy fails and destroys all line items added to the DOM,
   * and then recreates them, which causes the snap transition to fail.
   *
   * Solution: to combat this, we add a unique id offset to the uniqueId, which we call uniqueIdWithTransitionOffset.
   * This offset increments by 1 right before the rowViewModel is about to be snapped into view.
   * This stops the trackBy decorator from destroying/recreating the line items added to the DOM.
   */
  uniqueIdWithTransitionOffset(): string {
    return this.uniqueId() + this.uniqueIdOffset;
  }

  getVariantFromGridColumn(columnVM: SectionColumnViewModel): Variant {
    return this.rowVariants?.find(v => {
      const layoutType = columnVM?.sectionLayoutType;
      if (!this.section?.enabledVariantIds?.find(i => i === v?.id)) {
        return false;
      } else if (layoutType === SectionLayoutType.List || layoutType === SectionLayoutType.ChildVariantList) {
        return true;
      } else {
        return v?.getGridNames(layoutType, this.locationConfig?.locationId)?.contains(columnVM.columnTitle);
      }
    });
  }

  /* ************************** Label Component Interface ************************** */

  getMenuForLabelComponent(): Menu {
    return this.menu;
  }

  getSectionForLabelComponent(): SectionWithProducts {
    return this.section;
  }

  getVariantsForLabelComponent(): Variant[] {
    return this.getScopedVisibleVariants();
  }

  getLocationConfigForLabelComponent(): LocationConfiguration {
    return this.locationConfig;
  }

  getCompanyConfigForLabelComponent(): CompanyConfiguration {
    return this.companyConfig;
  }

  getShutOffLabelForLabelComponent(): boolean {
    return !this.menu?.getShowInlineLabels();
  }

  getOverridePriceStreamForLabelComponent(): LocationPriceStream {
    return null;
  }

  /* ****************************************************************************** */

}
