import { ProductMenu } from '../product-menu';
import { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { Type } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { MenuHeaderTitleComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title.component';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { SectionLayoutType } from '../../enum/dto/section-layout-type.enum';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class CleanMenu extends ProductMenu {

  getThemeClass(): string {
    return 'clean-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    if (this.isLandscape()) {
      return MenuHeaderTitleImageComponent;
    } else {
      return MenuHeaderTitleComponent;
    }
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getShowBadgesInlineOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getShowBadgesInline(): boolean {
    return true;
  }

  getPriceColumnTitle(col: SectionColumnViewModel): string {
    if (col.sectionLayoutType === SectionLayoutType.Grid) {
      return col.columnTitle;
    } else {
      return super.getPriceColumnTitle(col);
    }
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getShowFooter(): boolean {
    return !this.isLandscape();
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.playfairDisplayRegular,
      FONT.openSansRegular,
      FONT.openSansSemiBold
    ];
  }

}
