import { PrintCardMenu } from './print-card-menu';
import { Position } from '../../enum/shared/position.enum';
import { FONT } from '../font-style-sheets';

export class PlainJanePrintCardMenu extends PrintCardMenu {

  getFontStyleSheets() {
    return [
      FONT.poppinsMedium,
      FONT.poppinsSemiBold,
      FONT.poppinsExtraBold,
      FONT.poppinsBoldItalic,
      FONT.realistRegular,
      FONT.realistWideExtraBoldItalic,
      FONT.realistBold,
      FONT.realistBoldItalic,
      FONT.realistWideRegular,
      FONT.realistWideBoldItalic,
      FONT.realistExtraBold
    ];
  }

  shouldStandardizeDominantStrainType() {
    return false;
  }

  primaryPriceColumnAlsoShowOriginalPriceIfOnSalePosition(): Position {
    return Position.Top;
  }

}
