import { ProductMenu } from '../product-menu';
import { SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { OpacityLocation } from '../../shared/opacity-location.enum';
import { Type } from '@angular/core';
import { MenuSectionHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section-header/menu-section-header.component';
import { RuntzSectionHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section-header/runtz-section-header/runtz-section-header.component';
import { EmptyMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/empty-menu-footer/empty-menu-footer.component';
import { ProductMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/product-menu-footer.component';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class RuntzMenu extends ProductMenu {

  getThemeClass(): string {
    return 'runtz-theme';
  }

  getHeaderType(): Type<MenuHeaderTitleImageComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getSectionsHeaderType(): Type<MenuSectionHeaderComponent> {
    return RuntzSectionHeaderComponent;
  }

  getFooterType(): Type<ProductMenuFooterComponent> {
    return EmptyMenuFooterComponent;
  }

  /* ************************** Framed Background ************************** */

  getSectionsWrapperClass(): string {
    return 'runtz-sections-container';
  }

  getOpacityLocation(): OpacityLocation {
    return OpacityLocation.SECTION_CONTAINER;
  }

  getSectionsBackgroundColor(): string {
    return this.getBackgroundColorWithOpacity();
  }

  /* *********************************************************************** */

  getTitleSectionBottomMargin(): string {
    return '0';
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getColumnGroupingSpacerWidthPercentage(): number {
    return 0;
  }

  brandInlineWithProductName(): boolean {
    return false;
  }

  getShowProductSubtitle(): boolean {
    return false;
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.Badge, 0],
      [SectionColumnProductInfoType.ProductTitle, 1],
      [SectionColumnProductInfoType.StrainType, 2],
      [SectionColumnProductInfoType.Brand, 3],
      [SectionColumnProductInfoType.QuantityAndSize, 4],
      [SectionColumnProductInfoType.Quantity, 5],
      [SectionColumnProductInfoType.Size, 6],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(7),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(8),
      [SectionColumnProductInfoType.VariantPrice, 9],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 10],
    ]);
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.poppinsMedium,
      FONT.workSansSemiBold,
      FONT.workSansMedium,
      FONT.workSansBold
    ];
  }

}
