import { ProductMenu } from '../product-menu';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { SectionColumnProductInfoType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { MenuType } from '../../enum/dto/menu-type.enum';
import type { SectionWithProducts } from '../section/section-with-products';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';
import { FONT, FontStyleSheet } from '../font-style-sheets';

export class MaryJaneMenu extends ProductMenu {

  getThemeClass(): string {
    return (this.type === MenuType.WebMenu) ? 'mary-jane-theme web-menu' : 'mary-jane-theme';
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return false;
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getDefaultBackgroundImageUrl(): string {
    return 'assets/images/PaperTexture.png';
  }

  getPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel,
  ): string {
    if (!this.getDropDecimal()) {
      const price = this.getVariantPricing(priceStream, locationId, s, rowVM, colVm, false, true);
      const dec = price.split('.')?.[1];
      // decimals and formatting are included. ie: $1000.00
      if (price === '-' || price.length > 7 || dec === '') {
        return '';
      } else {
        return dec;
      }
    } else {
      return '';
    }
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    const outBadges = (sectionColumnViewModel: SectionColumnViewModel) => {
      return sectionColumnViewModel.columnType !== SectionColumnProductInfoType.Badge;
    };
    if (sectionColumnViewModels?.filter(outBadges)?.length >= 5) {
      return ColumnUtils.oneBigGrouping();
    }
    return ColumnUtils.standardizedGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    const outBadges = (sectionColumnViewModel: SectionColumnViewModel) => {
      return sectionColumnViewModel.columnType !== SectionColumnProductInfoType.Badge;
    };
    if (sectionColumnViewModels?.filter(outBadges)?.length >= 7) {
      return ColumnUtils.oneBigGrouping();
    }
    return ColumnUtils.standardizedGrouping();
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.montHeavy,
      FONT.poppinsBold,
      FONT.poppinsRegular,
      FONT.poppinsMedium,
      FONT.poppinsSemiBold,
      FONT.poppinsBlack
    ];
  }

}
