import { ProductMenu } from './product-menu';
import { Type } from '@angular/core';
import { SpotlightHeaderComponent } from '../../modules/display/components/menus/spotlight-menu/building-blocks/menu-header/spotlight-header/spotlight-header.component';
import { SpotlightFooterComponent } from '../../modules/display/components/menus/spotlight-menu/building-blocks/menu-footer/spotlight-footer/spotlight-footer.component';
import { SectionType } from '../enum/dto/section-type.enum';
import { SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { Section } from './section/section';
import { SectionRowViewModel } from '../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { FontStyleSheet } from './font-style-sheets';

export class SpotlightMenu extends ProductMenu {

  public subTitle: string;

  protected override deserializeSections() {
    this.sections?.forEach(s => s.sectionType = SectionType.Spotlight);
    this.sections = window?.injector?.Deserialize?.arrayOf(Section, this.sections) ?? [];
  }

  protected override deserializeTemplateSections() {
    this.templateSections?.forEach(templateSection => templateSection.sectionType = SectionType.Spotlight);
    this.templateSections = window?.injector?.Deserialize?.arrayOf(Section, this.templateSections) ?? [];
  }

  getSectionWidthPercentage(): number {
    return 100;
  }

  getMenuClass(): string {
    return 'spotlight-menu';
  }

  getHeaderType(): Type<SpotlightHeaderComponent> {
    return SpotlightHeaderComponent;
  }

  getFooterType(): Type<SpotlightFooterComponent> {
    return SpotlightFooterComponent;
  }

  getShouldInflateColumnForRow(s: Section, col: SectionColumnViewModel, row: SectionRowViewModel): boolean {
    return true;
  }

  isInOverflowModeThatNeedsFlexWrap(): boolean {
    return super.isInOverflowModeThatNeedsFlexWrap() || this.hideOverflow();
  }

  getShouldSectionsContainerFlexWrap(): boolean {
    return this.isLandscape() || this.isInOverflowModeThatNeedsFlexWrap();
  }

  getColumnGroupingSpacerWidthPercentage(): number {
    return 0;
  }

  getShowFooter(): boolean {
    return false;
  }

  getFontStyleSheets(): FontStyleSheet[] {
    return [];
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.ProductTitle, 0],
      [SectionColumnProductInfoType.Brand, 1],
      [SectionColumnProductInfoType.Badge, 2],
      [SectionColumnProductInfoType.StrainType, 3],
      [SectionColumnProductInfoType.QuantityAndSize, 4],
      [SectionColumnProductInfoType.Quantity, 5],
      [SectionColumnProductInfoType.Size, 6],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(7),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(8),
      [SectionColumnProductInfoType.VariantPrice, 9],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 10],
    ]);
  }

}
